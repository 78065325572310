import { useMemo } from "react";
import { Link } from "../../../clay/link";
import { useQuickAllRecords } from "../../../clay/quick-cache";
import { useRecordContext } from "../../../clay/widgets";
import { FormWrapper } from "../../../clay/widgets/FormField";
import { LinkSetWidget } from "../../../clay/widgets/link-set-widget";
import { ROLE_CERTIFIED_FOREMAN, User, USER_META } from "../../user/table";
import { PROJECT_META } from "../table";
import * as React from "react";

const base = LinkSetWidget({
    meta: USER_META,
    name(record) {
        return record.name;
    },
});

export function ForemenWidget() {
    const project = useRecordContext(PROJECT_META)!;
    const users = useQuickAllRecords(USER_META);

    const foremen = useMemo(() => {
        if (!users || !project) {
            return [];
        }
        const entries = new Set<Link<User>>();
        for (const personnel of project.personnel) {
            if (personnel.role === ROLE_CERTIFIED_FOREMAN) {
                if (personnel.foremen.length > 0) {
                    for (const item of personnel.foremen) {
                        entries.add(item);
                    }
                } else {
                    const cf = users.find(
                        (item) => item.id.uuid === personnel.user
                    );
                    if (cf && cf.foremen.length == 1) {
                        entries.add(cf.foremen[0]);
                    }
                }
            }
        }
        return Array.from(entries).map(
            (entry) => users.find((x) => x.id.uuid == entry)!
        );
    }, [project, users]);

    return (
        <FormWrapper label="Site Foremen">
            <base.component
                state={{}}
                data={foremen.map((x) => x.id.uuid)}
                dispatch={() => {}}
                status={{
                    mutable: false,
                    validation: [],
                }}
            />
        </FormWrapper>
    );
}

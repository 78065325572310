import * as React from "react";
import { Table } from "react-bootstrap";
import { useProjectRecordQuery } from "../../../clay/api";
import { Dictionary } from "../../../clay/common";
import { longDate } from "../../../clay/LocalDate";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi, useQuickCache } from "../../../clay/quick-cache";
import {
    FormWrapper,
    Optional,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { FieldRow } from "../../../clay/widgets/layout";
import { hasPermission } from "../../../permissions";
import { useUser } from "../../state";
import UserAndDateWidget from "../../user-and-date/UserAndDateWidget.widget";
import { DETAIL_SHEET_META } from "../detail-sheet/table";
import { Project, PROJECT_META } from "../table";

export type Data = Project;

export const Fields = {
    addedToAccountingSoftware: Optional(UserAndDateWidget),
    completion: OptionalFormField(UserAndDateWidget),
};

function Component(props: Props) {
    const cache = useQuickCache();
    const user = useUser();
    const detailSheets = useProjectRecordQuery(
        DETAIL_SHEET_META,
        props.data.id.uuid
    );

    return (
        <>
            <FieldRow>
                <FormWrapper label="Entered into Spectrum">
                    <widgets.addedToAccountingSoftware readOnly />
                </FormWrapper>
            </FieldRow>
            {detailSheets && (
                <Table>
                    <thead>
                        <tr>
                            <th>Number</th>
                            <th>Generated Date</th>
                            <th>Added to Accounting</th>
                        </tr>
                    </thead>
                    <tbody>
                        {detailSheets.map((sheet) => (
                            <tr>
                                <td>
                                    {!sheet.number.isZero() &&
                                        sheet.number.toString()}
                                </td>
                                <td>{longDate(sheet.date)}</td>
                                <td>
                                    {longDate(
                                        sheet.addedToAccountingSoftware.date
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <FieldRow>
                <widgets.completion
                    label="Final Calculation of Payout"
                    disableSet
                    enableReset={hasPermission(
                        user,
                        "Project",
                        "reset-completed-project"
                    )}
                />
            </FieldRow>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.addedToAccountingSoftware> &
    WidgetContext<typeof Fields.completion>;
type ExtraProps = {};
type BaseState = {
    addedToAccountingSoftware: WidgetState<
        typeof Fields.addedToAccountingSoftware
    >;
    completion: WidgetState<typeof Fields.completion>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "ADDED_TO_ACCOUNTING_SOFTWARE";
          action: WidgetAction<typeof Fields.addedToAccountingSoftware>;
      }
    | { type: "COMPLETION"; action: WidgetAction<typeof Fields.completion> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.addedToAccountingSoftware,
        data.addedToAccountingSoftware,
        cache,
        "addedToAccountingSoftware",
        errors
    );
    subvalidate(
        Fields.completion,
        data.completion,
        cache,
        "completion",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "ADDED_TO_ACCOUNTING_SOFTWARE": {
            const inner = Fields.addedToAccountingSoftware.reduce(
                state.addedToAccountingSoftware,
                data.addedToAccountingSoftware,
                action.action,
                subcontext
            );
            return {
                state: { ...state, addedToAccountingSoftware: inner.state },
                data: { ...data, addedToAccountingSoftware: inner.data },
            };
        }
        case "COMPLETION": {
            const inner = Fields.completion.reduce(
                state.completion,
                data.completion,
                action.action,
                subcontext
            );
            return {
                state: { ...state, completion: inner.state },
                data: { ...data, completion: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    addedToAccountingSoftware: function (
        props: WidgetExtraProps<typeof Fields.addedToAccountingSoftware> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ADDED_TO_ACCOUNTING_SOFTWARE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "addedToAccountingSoftware",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.addedToAccountingSoftware.component
                state={context.state.addedToAccountingSoftware}
                data={context.data.addedToAccountingSoftware}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Added to Accounting Software"}
            />
        );
    },
    completion: function (
        props: WidgetExtraProps<typeof Fields.completion> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "COMPLETION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "completion", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.completion.component
                state={context.state.completion}
                data={context.data.completion}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Completion"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let addedToAccountingSoftwareState;
        {
            const inner = Fields.addedToAccountingSoftware.initialize(
                data.addedToAccountingSoftware,
                subcontext,
                subparameters.addedToAccountingSoftware
            );
            addedToAccountingSoftwareState = inner.state;
            data = { ...data, addedToAccountingSoftware: inner.data };
        }
        let completionState;
        {
            const inner = Fields.completion.initialize(
                data.completion,
                subcontext,
                subparameters.completion
            );
            completionState = inner.state;
            data = { ...data, completion: inner.data };
        }
        let state = {
            initialParameters: parameters,
            addedToAccountingSoftware: addedToAccountingSoftwareState,
            completion: completionState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    addedToAccountingSoftware: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.addedToAccountingSoftware>
    >;
    completion: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.completion>
    >;
};
// END MAGIC -- DO NOT EDIT

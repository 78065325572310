import { AdminTablePage } from "../../../clay/admin-table";
import DivisionWidget from "./Division.widget";

import Widget from "./Widget.widget";

export const MasterFormatCodesPages = AdminTablePage({
    rowWidget: Widget,
    columns: [
        {
            id: "code",
            label: "Code",
        },
        {
            id: "description",
            label: "Description",
        },
    ],
    compare(lhs, rhs) {
        return lhs.code.localeCompare(rhs.code);
    },
    adminCategory: "project",
});

export const MasterFormatDivisionsPages = AdminTablePage({
    rowWidget: DivisionWidget,
    columns: [
        {
            id: "prefix",
            label: "Prefix",
        },
        {
            id: "color",
            label: "Color",
        },
    ],
    compare(lhs, rhs) {
        return lhs.prefix.localeCompare(rhs.prefix);
    },
    adminCategory: "project",
});

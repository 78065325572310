import Decimal from "decimal.js";
import { Button } from "react-bootstrap";
import { daysAgo, ifNull, isNotNull, isNull } from "../../clay/queryFuncs";
import { useQuickCache, useQuickRecord } from "../../clay/quick-cache";
import TenderDetailsMiniWidget from "../project/quote-request/tender-details-mini.widget";
import { calcProjectSummary, PROJECT_META } from "../project/table";
import { useUser } from "../state";
import { MessageBody, MessageFooter, MessageHeader } from "./message";
import NotificationSource from "./NotificationSource";
import { ITEM_TYPE } from "./types";
import * as React from "react";

function Component(props: {
    id: string;
    setOpenItem: (item: ITEM_TYPE | null) => void;
}) {
    const project = useQuickRecord(PROJECT_META, props.id);
    const cache = useQuickCache();
    const user = useUser();

    if (!project) {
        return <></>;
    }

    const { state } = TenderDetailsMiniWidget.initialize(project, {} as any);

    return (
        <>
            <MessageHeader>
                {calcProjectSummary(project)} Quotation Late
            </MessageHeader>
            <MessageBody>
                <TenderDetailsMiniWidget.component
                    data={project}
                    state={state}
                    status={{ mutable: false, validation: [] }}
                    dispatch={() => {}}
                />
            </MessageBody>
            <MessageFooter>
                <Button
                    onClick={() => {
                        window.open(
                            "#/project/edit/" + props.id + "/estimates"
                        );
                        props.setOpenItem(null);
                    }}
                    style={{
                        marginLeft: "auto",
                        marginTop: "1em",
                        maxWidth: "10em",
                    }}
                >
                    Open Project
                </Button>
            </MessageFooter>
        </>
    );
}

export const TENDER_DUE_SOURCE = NotificationSource({
    key: "tender-coming-due",
    label(project) {
        return ifNull(daysAgo(project.tenderDue), new Decimal(0)).gt(
            new Decimal(0)
        )
            ? "TENDER OVERDUE"
            : "Tender Coming Due";
    },
    Component,
    table: PROJECT_META,
    sendToCategoryManager: true,
    sendToProjectRoleWithPermission: "Inbox-show-tender-coming-due",
    active(project) {
        return (
            isNull(project.projectLostDate) &&
            isNotNull(project.tenderDue) &&
            isNull(project.firstQuotationDate) &&
            daysAgo(project.tenderDue!)!.gt(new Decimal("-3"))
        );
    },
    date(project) {
        return project.tenderDue;
    },
    dated: true,
    priority: true,
});

import { RecordMeta } from "../../../clay/meta";
import { genUUID, UUID } from "../../../clay/uuid";
import { Version } from "../../../clay/version";

//!Data
export type SpectrumInvoiceType = {
    id: UUID;
    recordVersion: Version;
    code: string;
    description: string;
    active: boolean;
};

export const MATERIALS_SPECTRUM_ID = "00fc999d-79ba-479b-890a-e0903b72197d";
export const LABOUR_SPECTRUM_ID = "4dd1fc9e-b881-4b88-8254-ac25628d5c0a";
export const CONTINGENCY_SPECTURM_ID = "d0f1e9a2-a0b8-4a0b-9e38-fde304871790";
export const SUBCONTRACT_SPECTRUM_ID = "09df4992-04f4-404f-ba41-23a477abf4a8";

// BEGIN MAGIC -- DO NOT EDIT
export type SpectrumInvoiceTypeJSON = {
    id: string;
    recordVersion: number | null;
    code: string;
    description: string;
    active: boolean;
};

export function JSONToSpectrumInvoiceType(
    json: SpectrumInvoiceTypeJSON
): SpectrumInvoiceType {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        code: json.code,
        description: json.description,
        active: json.active,
    };
}
export type SpectrumInvoiceTypeBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    code?: string;
    description?: string;
    active?: boolean;
};

export function newSpectrumInvoiceType(): SpectrumInvoiceType {
    return JSONToSpectrumInvoiceType(repairSpectrumInvoiceTypeJSON(undefined));
}
export function repairSpectrumInvoiceTypeJSON(
    json: SpectrumInvoiceTypeBrokenJSON | undefined
): SpectrumInvoiceTypeJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            code: json.code || "",
            description: json.description || "",
            active: json.active || false,
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            code: undefined || "",
            description: undefined || "",
            active: undefined || false,
        };
    }
}

export function SpectrumInvoiceTypeToJSON(
    value: SpectrumInvoiceType
): SpectrumInvoiceTypeJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        code: value.code,
        description: value.description,
        active: value.active,
    };
}

export const SPECTRUM_INVOICE_TYPE_META: RecordMeta<
    SpectrumInvoiceType,
    SpectrumInvoiceTypeJSON,
    SpectrumInvoiceTypeBrokenJSON
> & { name: "SpectrumInvoiceType" } = {
    name: "SpectrumInvoiceType",
    type: "record",
    repair: repairSpectrumInvoiceTypeJSON,
    toJSON: SpectrumInvoiceTypeToJSON,
    fromJSON: JSONToSpectrumInvoiceType,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        code: { type: "string" },
        description: { type: "string" },
        active: { type: "boolean" },
    },
    userFacingKey: null,
    functions: {},
    segments: {},
};

// END MAGIC -- DO NOT EDIT

import { faPrint } from "@fortawesome/free-solid-svg-icons";
import Decimal from "decimal.js";
import * as React from "react";
import { Table } from "react-bootstrap";
import { generateDocument, useRecordQuery } from "../../../clay/api";
import { Dictionary, Money } from "../../../clay/common";
import { EditActionButton } from "../../../clay/edit-context";
import { GenerateButton } from "../../../clay/generate-button";
import { propCheck } from "../../../clay/propCheck";
import { sumMap } from "../../../clay/queryFuncs";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    useRecordContext,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { MoneyStatic } from "../../../clay/widgets/money-widget";
import { PercentageStatic } from "../../../clay/widgets/percentage-widget";
import { StaticListWidget } from "../../../clay/widgets/StaticListWidget";
import ContingencyItemContractWidget from "../../contingency/ContingencyItemContractWidget.widget";
import {
    calcContingencyItemCertifiedForemanTotal,
    calcContingencyItemTotal,
    isLumpSumUnitType,
} from "../../contingency/table";
import { INVOICE_META } from "../../invoice/table";
import { CONTENT_AREA, TABLE_STYLE } from "../../styles";
import ProjectScheduleExWidget from "../ProjectScheduleExWidget.widget";
import { PROJECT_META } from "../table";
import { useNonCfItems } from "./NonCfExpensesTab.widget";
import {
    DetailSheet,
    DETAIL_SHEET_META,
    resolveDetailSheetSchedules,
    scheduleLines,
} from "./table";

export type Data = DetailSheet;

export const Fields = {
    schedules: ListWidget(ProjectScheduleExWidget, { emptyOk: true }),
    contingencyItems: StaticListWidget(ContingencyItemContractWidget),
};

function validate(data: Data, cache: QuickCacheApi) {
    let errors = baseValidate(data, cache);

    if (data.change) {
        errors = errors.filter((error) => error.field !== "projectedStartDate");
    }

    return errors;
}

function reduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    const inner = baseReduce(state, data, action, context);
    return {
        state: inner.state,
        data: resolveDetailSheetSchedules(inner.data),
    };
}

function actionFinalize(state: State, data: Data) {
    return {
        state,
        data: {
            ...data,
            date: data.date || new Date(),
        },
    };
}

const CONTINGENCY_STYLE: React.CSSProperties = {
    fontStyle: "italic",
};

function Component(props: Props) {
    const project = useRecordContext(PROJECT_META);

    const projectRevenue = sumMap(
        project.projectSchedules,
        (x) => x.price
    ).plus(
        sumMap(project.projectContingencyItems, (x) =>
            calcContingencyItemTotal(x)
        )
    );

    const divisor = projectRevenue.lessThan(2000)
        ? new Decimal("5")
        : projectRevenue.lessThan(10000)
        ? new Decimal("6")
        : new Decimal("7");

    const previousDetailSheets =
        useRecordQuery(
            DETAIL_SHEET_META,
            {
                filters: [
                    {
                        column: "project",
                        filter: {
                            equal: props.data.project,
                        },
                    },
                    {
                        column: "number",
                        filter: {
                            lesser: props.data.number.toString(),
                        },
                    },
                    {
                        column: "date",
                        filter: {
                            not_equal: null,
                        },
                    },
                ],
            },
            [props.data.project, props.data.number]
        ) || [];

    const contingencyItemBillingItems = React.useMemo(() => {
        let nextUnitRateItem = 1;
        let nextTimeAndMaterialsItem = 1;
        for (const previousDetailSheet of previousDetailSheets) {
            for (const contingencyItem of previousDetailSheet.contingencyItems) {
                if (isLumpSumUnitType(contingencyItem.type)) {
                    nextTimeAndMaterialsItem += 1;
                } else {
                    nextUnitRateItem += 1;
                }
            }
        }

        return props.data.contingencyItems.map((contingencyItem) => {
            if (isLumpSumUnitType(contingencyItem.type)) {
                return nextTimeAndMaterialsItem++;
            } else {
                return nextUnitRateItem++;
            }
        });
    }, [previousDetailSheets, props.data.contingencyItems]);

    const invoices = useRecordQuery(
        INVOICE_META,
        {
            filters: [
                {
                    column: "project",
                    filter: {
                        equal: props.data.project,
                    },
                },
            ],
            sorts: ["number"],
        },
        [props.data.id.uuid]
        // Only ask for the list of invoice if
        // no invoice is currently open
    );
    const clickPrint = React.useCallback(() => {
        generateDocument(
            "detailSheet",
            [props.data.id.uuid, props.data.change ? "1" : "0"],
            false
        );
    }, [props.dispatch]);

    const fixedContractTotal = sumMap(
        props.data.schedules,
        (schedule) => schedule.price
    );
    const unitContractTotal = sumMap(
        props.data.contingencyItems,
        calcContingencyItemTotal
    );

    const contractTotal = fixedContractTotal.plus(unitContractTotal);

    const fixedCfAmountTotal = sumMap(
        props.data.schedules,
        (schedule) => schedule.certifiedForemanContractAmount
    );
    const unitRateCfAmountTotal = sumMap(props.data.contingencyItems, (item) =>
        item.nonCfExpense
            ? new Decimal(0)
            : calcContingencyItemCertifiedForemanTotal(item)
    );

    const cfAmountTotal = fixedCfAmountTotal.plus(unitRateCfAmountTotal);

    function summaryRow(
        label: string,
        number: Money,
        contingency: boolean = false
    ) {
        return (
            <tr>
                <td>{label}</td>
                <td>
                    <MoneyStatic
                        value={number}
                        style={contingency ? CONTINGENCY_STYLE : undefined}
                    />
                </td>
                <td>
                    <PercentageStatic
                        value={number
                            .dividedBy(contractTotal)
                            .toDecimalPlaces(4)}
                    />
                </td>
            </tr>
        );
    }

    const nonCfItems = useNonCfItems(props.data);
    const fixedNonCfTotal = sumMap(
        nonCfItems.filter((x) => x.source !== "contingency"),
        (item) => item.cost
    );
    const unitNonCfTotal = sumMap(
        nonCfItems.filter((x) => x.source === "contingency"),
        (item) => item.cost
    );
    const nonCfTotal = fixedNonCfTotal.plus(unitNonCfTotal);
    const lines = React.useMemo(() => scheduleLines(props.data), [props.data]);
    const deductions = contractTotal
        .times(new Decimal("0.01"))
        .plus(cfAmountTotal.times(new Decimal("0.0075")))
        .toDecimalPlaces(2);
    const profit = contractTotal
        .minus(deductions)
        .minus(cfAmountTotal)
        .minus(nonCfTotal);
    const remdalAmount = profit.dividedBy(divisor).toDecimalPlaces(2);
    const estimatorCommissions = remdalAmount
        .times(new Decimal("0.35"))
        .toDecimalPlaces(2);
    const managerComissions = remdalAmount
        .times(new Decimal("0.65"))
        .toDecimalPlaces(2);

    const remainder = contractTotal
        .minus(deductions)
        .minus(cfAmountTotal)
        .minus(nonCfTotal)
        .minus(estimatorCommissions)
        .minus(managerComissions);

    return (
        <>
            <div {...CONTENT_AREA}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginTop: "2em",
                        marginBottom: "2em",
                    }}
                >
                    <table
                        {...TABLE_STYLE}
                        style={{
                            width: "100%",
                            maxWidth: "75em",
                            marginRight: "auto",
                        }}
                    >
                        <thead>
                            <tr>
                                <th style={{ width: "2em" }} />
                                <th>Fixed Price Items</th>
                                <th style={{ width: "4em" }}>Group Code</th>
                                <th style={{ width: "8em" }}>Billing Item</th>
                                <th style={{ width: "11em" }}>
                                    Remdal Contract Amount
                                </th>
                                <th style={{ width: "11em" }}>
                                    CF Contract Amount
                                </th>
                                {props.data.revamped && (
                                    <th style={{ width: "11em" }}>
                                        Non-CF Expenses
                                    </th>
                                )}
                                <th style={{ width: "1em" }} />
                            </tr>
                        </thead>
                        <widgets.schedules
                            containerClass="tbody"
                            extraItemForAdd
                            itemProps={{
                                invoices,
                                lines,
                                scheduleOffset: previousDetailSheets.reduce(
                                    (x, y) => y.schedules.length + x,
                                    0
                                ),
                            }}
                        />{" "}
                        <tbody>
                            <tr>
                                <th />
                                <th
                                    colSpan={3}
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    Total
                                </th>
                                <th>
                                    <MoneyStatic value={fixedContractTotal} />
                                </th>
                                <th>
                                    <MoneyStatic value={fixedCfAmountTotal} />
                                </th>

                                {props.data.revamped && (
                                    <th>
                                        <MoneyStatic value={fixedNonCfTotal} />
                                    </th>
                                )}
                            </tr>
                        </tbody>
                        <thead>
                            <tr>
                                <th style={{ width: "2em" }} />
                                <th>Unit Rate Items</th>
                                <th style={{ width: "4em" }}>Group Code</th>
                                <th style={{ width: "8em" }}>Billing Item</th>
                                <th style={{ width: "11em" }}>
                                    Remdal Contract Amount
                                </th>
                                <th style={{ width: "11em" }}>
                                    CF Contract Amount
                                </th>
                                {props.data.revamped && (
                                    <th style={{ width: "11em" }}>
                                        Non-CF Expenses
                                    </th>
                                )}
                                <th style={{ width: "1em" }} />
                            </tr>
                        </thead>
                        <tbody>
                            <widgets.contingencyItems
                                itemFn={(index) => ({
                                    billingItem:
                                        contingencyItemBillingItems[index],
                                })}
                            />
                        </tbody>
                        <tfoot>
                            <tr>
                                <th />
                                <th
                                    colSpan={3}
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    Totals
                                </th>
                                <th>
                                    <MoneyStatic
                                        value={unitContractTotal}
                                        style={CONTINGENCY_STYLE}
                                    />
                                </th>
                                <th>
                                    <MoneyStatic
                                        value={unitRateCfAmountTotal}
                                        style={CONTINGENCY_STYLE}
                                    />
                                </th>

                                {props.data.revamped && (
                                    <th>
                                        <MoneyStatic
                                            value={unitNonCfTotal}
                                            style={CONTINGENCY_STYLE}
                                        />
                                    </th>
                                )}
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <Table
                        style={{
                            width: "fit-content",
                            flex: "0 0 auto",
                            height: "fit-content",
                        }}
                    >
                        <thead>
                            <tr>
                                <th />
                                <th>Value</th>
                                <th>% of Contract</th>
                            </tr>
                        </thead>
                        <tbody>
                            {summaryRow("Fixed Rate Items", fixedContractTotal)}
                            {summaryRow(
                                "Unit Rate Items",
                                unitContractTotal,
                                true
                            )}
                            {summaryRow(
                                "Total Remdal Contract Amount",
                                contractTotal
                            )}
                        </tbody>
                    </Table>

                    <Table
                        style={{
                            width: "fit-content",
                            flex: "0 0 auto",
                            height: "fit-content",
                            marginLeft: "1em",
                        }}
                    >
                        <thead>
                            <tr>
                                <th />
                                <th>Value</th>
                                <th>% of Contract</th>
                            </tr>
                        </thead>
                        <tbody>
                            {summaryRow(
                                "CF Contract - Fixed Rate Items",
                                fixedCfAmountTotal
                            )}
                            {summaryRow(
                                "CF Allowances - Unit Rate Items",
                                unitRateCfAmountTotal,
                                true
                            )}
                            {summaryRow("Non-CF Expenses", nonCfTotal)}
                            {summaryRow(
                                "Projected Remdal Deductions",
                                deductions
                            )}
                            {summaryRow(
                                "Projected Est Commissions",
                                estimatorCommissions
                            )}
                            {summaryRow(
                                "Projected PM Commissions",
                                managerComissions
                            )}
                            {summaryRow("Projected Remdal GM", remainder)}
                        </tbody>
                    </Table>
                </div>
            </div>
            {props.data.certifiedForeman === null ? (
                <EditActionButton
                    label="Print Detail Sheet"
                    icon={faPrint}
                    variant="primary"
                    onClick={clickPrint}
                    action={{
                        disabled: false,
                        active: false,
                        onClick: clickPrint,
                        completed: false,
                    }}
                />
            ) : (
                <GenerateButton label="Generate Detail Sheet" />
            )}
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.schedules> &
    WidgetContext<typeof Fields.contingencyItems>;
type ExtraProps = {};
type BaseState = {
    schedules: WidgetState<typeof Fields.schedules>;
    contingencyItems: WidgetState<typeof Fields.contingencyItems>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "SCHEDULES"; action: WidgetAction<typeof Fields.schedules> }
    | {
          type: "CONTINGENCY_ITEMS";
          action: WidgetAction<typeof Fields.contingencyItems>;
      }
    | { type: "FINALIZE" };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.schedules, data.schedules, cache, "schedules", errors);
    subvalidate(
        Fields.contingencyItems,
        data.contingencyItems,
        cache,
        "contingencyItems",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "SCHEDULES": {
            const inner = Fields.schedules.reduce(
                state.schedules,
                data.schedules,
                action.action,
                subcontext
            );
            return {
                state: { ...state, schedules: inner.state },
                data: { ...data, schedules: inner.data },
            };
        }
        case "CONTINGENCY_ITEMS": {
            const inner = Fields.contingencyItems.reduce(
                state.contingencyItems,
                data.contingencyItems,
                action.action,
                subcontext
            );
            return {
                state: { ...state, contingencyItems: inner.state },
                data: { ...data, contingencyItems: inner.data },
            };
        }
        case "FINALIZE":
            return actionFinalize(state, data);
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    schedules: function (
        props: WidgetExtraProps<typeof Fields.schedules> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SCHEDULES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "schedules", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.schedules.component
                state={context.state.schedules}
                data={context.data.schedules}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Schedules"}
            />
        );
    },
    contingencyItems: function (
        props: WidgetExtraProps<typeof Fields.contingencyItems> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CONTINGENCY_ITEMS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "contingencyItems", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.contingencyItems.component
                state={context.state.contingencyItems}
                data={context.data.contingencyItems}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Contingency Items"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: DETAIL_SHEET_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let schedulesState;
        {
            const inner = Fields.schedules.initialize(
                data.schedules,
                subcontext,
                subparameters.schedules
            );
            schedulesState = inner.state;
            data = { ...data, schedules: inner.data };
        }
        let contingencyItemsState;
        {
            const inner = Fields.contingencyItems.initialize(
                data.contingencyItems,
                subcontext,
                subparameters.contingencyItems
            );
            contingencyItemsState = inner.state;
            data = { ...data, contingencyItems: inner.data };
        }
        let state = {
            initialParameters: parameters,
            schedules: schedulesState,
            contingencyItems: contingencyItemsState,
        };
        return {
            state,
            data,
        };
    },
    validate: validate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={DETAIL_SHEET_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: reduce,
};
export default Widget;
type Widgets = {
    schedules: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.schedules>
    >;
    contingencyItems: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.contingencyItems>
    >;
};
// END MAGIC -- DO NOT EDIT

import Decimal from "decimal.js";
import React from "react";
import { Button, Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import { sumMap } from "../../../clay/queryFuncs";
import { formatMoney } from "../../estimate/TotalsWidget.widget";

function ShowValue(props: { value: string | Decimal | null; money: boolean }) {
    if (props.value === null) {
        return <></>;
    }
    if (props.value instanceof Decimal) {
        return (
            <>
                {props.money
                    ? formatMoney(props.value)
                    : props.value.toString()}
            </>
        );
    } else {
        return <>{props.value}</>;
    }
}

export function ExportDataTable<T>(props: {
    rows: T[] | undefined;
    columns: Record<string, (t: T) => string | Decimal | null>;
    name: string;
    money: Set<String>;
}) {
    const { rows } = props;

    const onExport = React.useCallback(() => {
        exportTable<T>(props);
    }, [rows, props.name, props.columns]);
    if (!props.rows) {
        return <></>;
    }
    return (
        <>
            <Table style={{ width: "fit-content" }}>
                <thead>
                    {Object.keys(props.columns).map((key) => (
                        <td>{key}</td>
                    ))}
                </thead>
                <tbody>
                    {rows &&
                        rows.map((row, index) => (
                            <tr key={index}>
                                {Object.entries(props.columns).map(
                                    ([key, f]) => {
                                        const value = f(row);
                                        return (
                                            <td
                                                style={{
                                                    textAlign:
                                                        value instanceof Decimal
                                                            ? "right"
                                                            : "left",
                                                }}
                                            >
                                                <ShowValue
                                                    value={value}
                                                    money={props.money.has(key)}
                                                />
                                            </td>
                                        );
                                    }
                                )}
                            </tr>
                        ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total</th>
                        {Object.entries(props.columns)
                            .slice(1)
                            .map(([key, f]) => {
                                const values = props
                                    .rows!.map(f)
                                    .filter(
                                        (x) => x instanceof Decimal
                                    ) as Decimal[];

                                if (values.length > 0) {
                                    return (
                                        <th
                                            key={key}
                                            style={{ textAlign: "right" }}
                                        >
                                            <ShowValue
                                                value={sumMap(values, (x) => x)}
                                                money={props.money.has(key)}
                                            />
                                        </th>
                                    );
                                } else {
                                    return <th key={key}></th>;
                                }
                            })}
                    </tr>
                </tfoot>
            </Table>
            <Button onClick={onExport}>Export</Button>
        </>
    );
}
export function exportTable<T>(props: {
    rows: T[] | undefined;
    columns: Record<string, (t: T) => string | Decimal | null>;
    name: string;
}) {
    const { rows } = props;
    if (!rows) {
        return;
    }
    const data: any[][] = [];
    const header = [];
    for (const key of Object.keys(props.columns)) {
        header.push(key);
    }
    data.push(header);
    for (const row of rows) {
        const row_data: any[] = [];
        for (const [key, f] of Object.entries(props.columns)) {
            const value = f(row);
            if (value instanceof Decimal) {
                row_data.push({
                    t: "n",
                    v: value.toString(),
                });
            } else {
                row_data.push(value);
            }
        }
        data.push(row_data);
    }
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, sheet);
    XLSX.writeFile(workbook, props.name);
}

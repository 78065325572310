import * as React from "react";
import { Button, ListGroup, ListGroupItem, Table } from "react-bootstrap";
import { patchRecord } from "../../clay/api";
import { isNotNull, isNull } from "../../clay/queryFuncs";
import { useQuickCache, useQuickRecord } from "../../clay/quick-cache";
import { FormWrapper } from "../../clay/widgets/FormField";
import { StaticTextField } from "../../clay/widgets/TextWidget";
import { calcAddressLineFormatted } from "../address";
import {
    DetailSheet,
    DetailSheetBrokenJSON,
    DetailSheetJSON,
    DETAIL_SHEET_META,
} from "../project/detail-sheet/table";
import { calcProjectSummary, PROJECT_META } from "../project/table";
import { ROLE_META } from "../roles/table";
import {
    ROLE_CERTIFIED_FOREMAN,
    ROLE_ESTIMATOR,
    ROLE_OBSERVER,
    ROLE_PROJECT_MANAGER,
    USER_META,
} from "../user/table";
import { MessageBody, MessageFooter, MessageHeader } from "./message";
import NotificationSource from "./NotificationSource";
import { ITEM_TYPE } from "./types";

function Component(props: {
    id: string;
    setOpenItem: (item: ITEM_TYPE | null) => void;
}) {
    const detailSheet = useQuickRecord(DETAIL_SHEET_META, props.id);

    const project = useQuickRecord(PROJECT_META, detailSheet?.project || null);

    const personnel = React.useMemo(() => {
        if (!project) return [];
        const ROLE_ORDER = [
            ROLE_ESTIMATOR,
            ROLE_PROJECT_MANAGER,
            ROLE_CERTIFIED_FOREMAN,
            ROLE_OBSERVER,
        ];
        const people = project.personnel.filter(
            (x) => ROLE_ORDER.indexOf(x.role!) !== -1
        );
        people.sort(
            (a, b) => ROLE_ORDER.indexOf(a.role!) - ROLE_ORDER.indexOf(b.role!)
        );
        return people;
    }, [project?.personnel]);
    const cache = useQuickCache();

    const markAdded = React.useCallback(async () => {
        await patchRecord(DETAIL_SHEET_META, "inbox", props.id, {
            addedToSiteDocsDate: [null, new Date()],
        });
        props.setOpenItem(null);
    }, [props.id, props.setOpenItem]);

    if (!detailSheet || !project) {
        return <></>;
    }
    return (
        <>
            <MessageHeader>{calcProjectSummary(project)}</MessageHeader>
            <MessageBody>
                <FormWrapper label="Client's Name">
                    <StaticTextField value={project.customer} />
                </FormWrapper>
                <FormWrapper label="Site Address">
                    <StaticTextField
                        value={calcAddressLineFormatted(project.siteAddress)}
                    />
                </FormWrapper>
                <ListGroup>
                    {detailSheet.schedules.map((schedule) => (
                        <ListGroupItem key={schedule.id.uuid}>
                            {schedule.description}
                        </ListGroupItem>
                    ))}
                </ListGroup>
                <Table>
                    <thead>
                        <tr>
                            <th>Remdal Personnel</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {personnel.map((person) => (
                            <tr>
                                <td>
                                    {cache.get(USER_META, person.user)?.name}
                                </td>
                                <td>
                                    {cache.get(ROLE_META, person.role)?.name}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </MessageBody>
            <MessageFooter>
                <Button
                    style={{ marginLeft: "auto", display: "block" }}
                    onClick={markAdded}
                >
                    Mark as Added
                </Button>
            </MessageFooter>
        </>
    );
}

export const ADD_TO_SITE_DOCS_SOURCE = NotificationSource<
    DetailSheet,
    DetailSheetJSON,
    DetailSheetBrokenJSON
>({
    key: "add-to-sitedocs",
    label(project) {
        return "New [SQUAD] Project Scheduled";
    },
    Component,
    table: DETAIL_SHEET_META,
    date(detailSheet) {
        return detailSheet.date;
    },
    sendToCategoryPermission: "Inbox-site-docs-",
    active(detailSheet: DetailSheet) {
        return (
            isNotNull(detailSheet.date) &&
            !detailSheet.change &&
            detailSheet.date! > new Date(2024, 8, 1) &&
            isNull(detailSheet.addedToSiteDocsDate)
        );
    },
});

import * as React from "react";
import { Button } from "react-bootstrap";
import { Dictionary } from "../../clay/common";
import { propCheck } from "../../clay/propCheck";
import { QuickCacheApi, useQuickCache } from "../../clay/quick-cache";
import { FormField, FormWrapper, Optional } from "../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../clay/widgets/index";
import { FieldRow } from "../../clay/widgets/layout";
import { StaticTextField, TextWidget } from "../../clay/widgets/TextWidget";
import AddressWidget from "../AddressWidget.widget";
import { useUser } from "../state";
import {
    calcUserCodeName,
    ROLE_CERTIFIED_FOREMAN,
    ROLE_PROJECT_MANAGER,
    USER_META,
} from "../user/table";
import { PersonnelListWidget } from "./personnel/list-widget";
import ProjectDescriptionCategoryWidget from "./projectDescriptionDetail/ProjectDescriptionCategoryWidget.widget";
import { Project, PROJECT_META } from "./table";

export type Data = Project;

export const Fields = {
    personnel: Optional(PersonnelListWidget),
    siteAddress: AddressWidget,
    customer: FormField(TextWidget),
    projectDescription: FormField(ProjectDescriptionCategoryWidget),
};

function Component(props: Props) {
    const cache = useQuickCache();
    const user = useUser();

    return (
        <>
            <FieldRow>
                <FormWrapper label="Project #" style={{ maxWidth: "5em" }}>
                    <StaticTextField
                        value={props.data.projectNumber + "" || ""}
                    />
                </FormWrapper>
                <widgets.siteAddress readOnly />
            </FieldRow>
            {props.data.billingContacts.map((billingContact, index) => (
                <FieldRow key={index}>
                    <FormWrapper label="Billing Contact">
                        <StaticTextField value={billingContact.name} />
                    </FormWrapper>
                    <FormWrapper label="Billing Company">
                        <div style={{ display: "flex" }}>
                            <StaticTextField
                                value={
                                    billingContact.company.name ||
                                    "N/A - Personal Residence"
                                }
                            />
                            {billingContact.company.company && (
                                <Button
                                    style={{ width: "5em" }}
                                    onClick={() => {
                                        window.open(
                                            "#/company/edit/" +
                                                billingContact.company.company
                                        );
                                    }}
                                >
                                    Open
                                </Button>
                            )}
                        </div>
                    </FormWrapper>
                    <FormWrapper label="Billing Contact Email">
                        <StaticTextField
                            value={
                                billingContact.company.billingContactEmail ||
                                billingContact.email
                            }
                        />
                    </FormWrapper>
                </FieldRow>
            ))}

            <FieldRow>
                <widgets.customer readOnly label="Client's Name" />
                <widgets.projectDescription label="Project Category" readOnly />
            </FieldRow>

            <FieldRow>
                {props.data.personnel
                    .filter((entry) => entry.role === ROLE_PROJECT_MANAGER)
                    .map((entry) => (
                        <FormWrapper label="Project Manager">
                            <StaticTextField
                                value={
                                    cache.get(USER_META, entry.user)?.name || ""
                                }
                            />
                        </FormWrapper>
                    ))}
            </FieldRow>
            <FieldRow>
                {props.data.personnel
                    .filter((entry) => entry.role === ROLE_CERTIFIED_FOREMAN)
                    .map((entry) => (
                        <FormWrapper label="Certified Foreman">
                            <StaticTextField
                                value={
                                    cache.get(USER_META, entry.user)
                                        ? calcUserCodeName(
                                              cache.get(USER_META, entry.user)!
                                          )
                                        : ""
                                }
                            />
                        </FormWrapper>
                    ))}
            </FieldRow>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.personnel> &
    WidgetContext<typeof Fields.siteAddress> &
    WidgetContext<typeof Fields.customer> &
    WidgetContext<typeof Fields.projectDescription>;
type ExtraProps = {};
type BaseState = {
    personnel: WidgetState<typeof Fields.personnel>;
    siteAddress: WidgetState<typeof Fields.siteAddress>;
    customer: WidgetState<typeof Fields.customer>;
    projectDescription: WidgetState<typeof Fields.projectDescription>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "PERSONNEL"; action: WidgetAction<typeof Fields.personnel> }
    | { type: "SITE_ADDRESS"; action: WidgetAction<typeof Fields.siteAddress> }
    | { type: "CUSTOMER"; action: WidgetAction<typeof Fields.customer> }
    | {
          type: "PROJECT_DESCRIPTION";
          action: WidgetAction<typeof Fields.projectDescription>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.personnel, data.personnel, cache, "personnel", errors);
    subvalidate(
        Fields.siteAddress,
        data.siteAddress,
        cache,
        "siteAddress",
        errors
    );
    subvalidate(Fields.customer, data.customer, cache, "customer", errors);
    subvalidate(
        Fields.projectDescription,
        data.projectDescription,
        cache,
        "projectDescription",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "PERSONNEL": {
            const inner = Fields.personnel.reduce(
                state.personnel,
                data.personnel,
                action.action,
                subcontext
            );
            return {
                state: { ...state, personnel: inner.state },
                data: { ...data, personnel: inner.data },
            };
        }
        case "SITE_ADDRESS": {
            const inner = Fields.siteAddress.reduce(
                state.siteAddress,
                data.siteAddress,
                action.action,
                subcontext
            );
            return {
                state: { ...state, siteAddress: inner.state },
                data: { ...data, siteAddress: inner.data },
            };
        }
        case "CUSTOMER": {
            const inner = Fields.customer.reduce(
                state.customer,
                data.customer,
                action.action,
                subcontext
            );
            return {
                state: { ...state, customer: inner.state },
                data: { ...data, customer: inner.data },
            };
        }
        case "PROJECT_DESCRIPTION": {
            const inner = Fields.projectDescription.reduce(
                state.projectDescription,
                data.projectDescription,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectDescription: inner.state },
                data: { ...data, projectDescription: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    personnel: function (
        props: WidgetExtraProps<typeof Fields.personnel> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PERSONNEL",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "personnel", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.personnel.component
                state={context.state.personnel}
                data={context.data.personnel}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Personnel"}
            />
        );
    },
    siteAddress: function (
        props: WidgetExtraProps<typeof Fields.siteAddress> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SITE_ADDRESS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "siteAddress", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.siteAddress.component
                state={context.state.siteAddress}
                data={context.data.siteAddress}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Site Address"}
            />
        );
    },
    customer: function (
        props: WidgetExtraProps<typeof Fields.customer> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CUSTOMER",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "customer", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.customer.component
                state={context.state.customer}
                data={context.data.customer}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Customer"}
            />
        );
    },
    projectDescription: function (
        props: WidgetExtraProps<typeof Fields.projectDescription> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_DESCRIPTION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectDescription",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectDescription.component
                state={context.state.projectDescription}
                data={context.data.projectDescription}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Description"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let personnelState;
        {
            const inner = Fields.personnel.initialize(
                data.personnel,
                subcontext,
                subparameters.personnel
            );
            personnelState = inner.state;
            data = { ...data, personnel: inner.data };
        }
        let siteAddressState;
        {
            const inner = Fields.siteAddress.initialize(
                data.siteAddress,
                subcontext,
                subparameters.siteAddress
            );
            siteAddressState = inner.state;
            data = { ...data, siteAddress: inner.data };
        }
        let customerState;
        {
            const inner = Fields.customer.initialize(
                data.customer,
                subcontext,
                subparameters.customer
            );
            customerState = inner.state;
            data = { ...data, customer: inner.data };
        }
        let projectDescriptionState;
        {
            const inner = Fields.projectDescription.initialize(
                data.projectDescription,
                subcontext,
                subparameters.projectDescription
            );
            projectDescriptionState = inner.state;
            data = { ...data, projectDescription: inner.data };
        }
        let state = {
            initialParameters: parameters,
            personnel: personnelState,
            siteAddress: siteAddressState,
            customer: customerState,
            projectDescription: projectDescriptionState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    personnel: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.personnel>
    >;
    siteAddress: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.siteAddress>
    >;
    customer: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.customer>
    >;
    projectDescription: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectDescription>
    >;
};
// END MAGIC -- DO NOT EDIT

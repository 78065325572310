import React from "react";
import { Link } from "../clay/link";
import { useQuickAllRecords, useQuickRecord } from "../clay/quick-cache";
import { SelectLinkWidgetAction } from "../clay/widgets/SelectLinkWidget";
import { ItemTypeLinkWidget } from "./estimate/types";
import { ItemType, ITEM_TYPE_META } from "./estimate/types/table";
import { useLocalFieldWidget } from "./inbox/useLocalWidget";
import { MasterFormatLinkWidget } from "./project/master-format-codes/link";
import {
    MasterFormatCode,
    MASTER_FORMAT_CODE_META,
} from "./project/master-format-codes/table";

type Action = {
    type: "ITEM_TYPE";
    action: SelectLinkWidgetAction<ItemType>;
};

export function useMasterFormatItemTypes(props: {
    contingency: boolean;
    data: {
        masterFormatCode: Link<MasterFormatCode>;
        itemType: Link<ItemType>;
    };
    dispatch: (action: Action) => void;
}) {
    const itemTypes = useQuickAllRecords(ITEM_TYPE_META);
    const allowedMasterFormatCodes = React.useMemo(() => {
        const allowed = new Set<Link<MasterFormatCode>>();
        if (itemTypes) {
            for (const itemType of itemTypes) {
                if (supportsItemType(props, itemType)) {
                    for (const masterFormatCode of itemType.masterFormatCodes) {
                        allowed.add(masterFormatCode);
                    }
                }
            }
        }
        return allowed;
    }, [itemTypes, props.contingency]);

    const itemType = useQuickRecord(ITEM_TYPE_META, props.data.itemType);

    React.useEffect(() => {
        if (
            props.data.masterFormatCode &&
            itemType &&
            !itemType.masterFormatCodes.includes(props.data.masterFormatCode)
        ) {
            props.dispatch({
                type: "ITEM_TYPE",
                action: {
                    type: "SET",
                    value: null,
                },
            });
        }
    }, [itemType, props.dispatch, props.data.masterFormatCode]);

    return {
        includeMasterFormat(code: MasterFormatCode) {
            return allowedMasterFormatCodes.has(code.id.uuid);
        },
        includeItemType(itemType: ItemType) {
            return (
                itemType.masterFormatCodes.includes(
                    props.data.masterFormatCode
                ) && supportsItemType(props, itemType)
            );
        },
        itemType,
    };
}

function supportsItemType(
    props: {
        contingency: boolean;
    },
    itemType: ItemType
) {
    return props.contingency ? itemType.contingency : itemType.regular;
}

export function useMasterFormatItemTypesWidgets(props: {
    contingency: boolean;
}) {
    const itemTypes = useQuickAllRecords(ITEM_TYPE_META);
    const allowedMasterFormatCodes = React.useMemo(() => {
        const allowed = new Set<Link<MasterFormatCode>>();
        if (itemTypes) {
            for (const itemType of itemTypes) {
                if (supportsItemType(props, itemType)) {
                    for (const masterFormatCode of itemType.masterFormatCodes) {
                        allowed.add(masterFormatCode);
                    }
                }
            }
        }
        return allowed;
    }, [itemTypes]);
    const masterFormatCode = useLocalFieldWidget(MasterFormatLinkWidget, null, {
        include(data) {
            return allowedMasterFormatCodes.has(data.id.uuid);
        },
    });
    const itemType = useLocalFieldWidget(ItemTypeLinkWidget, null, {
        include(itemType) {
            return (
                itemType.masterFormatCodes.includes(masterFormatCode.data) &&
                supportsItemType(props, itemType)
            );
        },
    });

    const itemTypeRecord = useQuickRecord(ITEM_TYPE_META, itemType.data);
    const masterFormatCodeRecord = useQuickRecord(
        MASTER_FORMAT_CODE_META,
        masterFormatCode.data
    );
    const ready = !!(
        masterFormatCode.data &&
        itemTypeRecord &&
        itemTypeRecord.masterFormatCodes.includes(masterFormatCode.data) &&
        masterFormatCodeRecord &&
        supportsItemType(props, itemTypeRecord)
    );

    return {
        masterFormatCode,
        itemType,
        ready,
        itemTypeRecord,
        masterFormatCodeRecord,
    };
}

import Decimal from "decimal.js";
import { every, find } from "lodash";
import { Percentage } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { PaginatedWidget } from "../../../clay/paginated-widget";
import { sumMap } from "../../../clay/queryFuncs";
import { newUUID } from "../../../clay/uuid";
import { ValidationError } from "../../../clay/widgets";
import { ContingencyItem } from "../../contingency/table";
import { FINISH_SCHEDULE_META } from "../../estimate/finish-schedule/table";
import { ITEM_TYPE_META } from "../../estimate/types/table";
import {
    calcQuotationExpectedFixedContractValue,
    QuotationOption,
    QUOTATION_META,
    Schedule,
} from "../../quotation/table";
import { sourceToProjectContingency } from "../ContractDetailsWidget.widget";
import { ProjectSchedule } from "../schedule";
import { Project } from "../table";
import AdminTab from "./AdminTab.widget";
import ContingencyItemTab from "./ContingencyItemsTab.widget";
import DetailSheetAllowancesWidget from "./DetailSheetAllowancesWidget.widget";
import DetailSheetContractDetailsWidget from "./DetailSheetContractDetailsWidget.widget";
import DetailSheetContractNotesWidget from "./DetailSheetContractNotesWidget.widget";
import DetailSheetFinishSchedulesWidget from "./DetailSheetFinishSchedulesWidget.widget";
import DetailSheetMainWidget from "./DetailSheetMainWidget.widget";
import DetailSheetOptionsWidget from "./DetailSheetOptionsWidget.widget";
import DetailSheetScopeOfWorkWidget from "./DetailSheetScopeOfWorkWidget.widget";
import NonCfExpensesTab from "./NonCfExpensesTab.widget";
import {
    DetailSheetOption,
    DETAIL_SHEET_META,
    resolveDetailSheetSchedules,
    scheduleLines,
} from "./table";
import TimeAndMaterialsRateTab from "./TimeAndMaterialsTab.widget";
import * as React from "react";

const DetailSheetWidgetBase = PaginatedWidget({
    dataMeta: DETAIL_SHEET_META,
    validate(detailSheet, cache, errors) {
        if (detailSheet.date) {
            return [];
        } else {
            return errors;
        }
    },
    process(detailSheet, cache, pageId, project: Project) {
        if (detailSheet.initialized) {
            return null;
        }

        const quotations = detailSheet.quotations.map((quotation) =>
            cache.get(QUOTATION_META, quotation)
        );

        const finishSchedules = cache.getAll(FINISH_SCHEDULE_META);
        const itemTypes = cache.getAll(ITEM_TYPE_META);

        if (pageId === "main") {
            return null;
        }

        if (!every(quotations) || !finishSchedules || !itemTypes) {
            return undefined;
        }

        const schedules: ProjectSchedule[] = detailSheet.change
            ? [
                  {
                      id: newUUID(),
                      originalOptionId: null,
                      originalScheduleId: null,
                      name: "Change Order #" + detailSheet.number.toString(),
                      description: "",
                      price: sumMap(quotations, (quotation) =>
                          calcQuotationExpectedFixedContractValue(quotation!)
                      ),
                      certifiedForemanContractAmount: new Decimal(0),
                      contingencyAllowance: false,
                      projectDescription: {
                          category: null,
                          description: null,
                          custom: "",
                      },
                      groupCode: null,
                      billingItem: null,
                  },
              ]
            : project.projectSchedules.map((schedule) => ({
                  ...schedule,
                  id: newUUID(),
              }));

        if (detailSheet.change && schedules[0].price.isZero()) {
            schedules.pop();
        }

        const schedulify = <S, T>(
            option: QuotationOption | undefined,
            id: Link<S>,
            f: (s: Schedule) => { item: Link<S>; portion: Percentage }[],
            item: T,
            g: (t: T, portion: Percentage) => T
        ) => {
            if (!option) {
                return [item];
            }
            const candidateSchedules =
                schedules.length == 1
                    ? schedules
                    : schedules.filter(
                          (x) => x.originalOptionId === option.id.uuid
                      );
            if (candidateSchedules.length === 0) {
                return [item];
            }
            if (option.schedules.length == 0) {
                return [
                    {
                        ...item,
                        schedule: candidateSchedules[0].id.uuid,
                    },
                ];
            } else {
                const entries = [];
                for (const schedule of option.schedules) {
                    const parts = f(schedule).find((item) => item.item === id);
                    if (parts) {
                        const detailSheetSchedule = candidateSchedules.find(
                            (x) => x.originalScheduleId === schedule.id.uuid
                        );
                        entries.push(
                            g(
                                {
                                    ...item,
                                    schedule:
                                        detailSheetSchedule?.id.uuid || null,
                                },
                                parts.portion
                            )
                        );
                    }
                }
                return entries;
            }
        };

        const options: DetailSheetOption[] = [];
        const contingencyItems: ContingencyItem[] = [];
        for (const quotation of quotations) {
            for (const option of quotation!.options) {
                if (
                    !detailSheet.change &&
                    project.selectedOptions.indexOf(option.id.uuid) === -1
                ) {
                    continue;
                }

                options.push({
                    id: newUUID(),
                    name: option.name,
                    description: option.description,
                    finishSchedule: option.details.finishSchedule.map(
                        (schedule) => ({
                            ...schedule,
                            id: newUUID(),
                            colour: "",
                        })
                    ),
                    allowances: option.details.allowances.flatMap((allowance) =>
                        schedulify(
                            option,
                            allowance.id.uuid,
                            (s) => s.allowances,
                            allowance,
                            (s, r) => ({
                                ...s,
                                cost: s.cost.times(r),
                                price: s.price.times(r),
                            })
                        )
                    ),
                    budget: option.details.actions.flatMap((action) =>
                        schedulify(
                            option,
                            action.id.uuid,
                            (s) => s.actions,
                            {
                                nonCfExpense: false,
                                masterFormatCode: action.masterFormatCode,
                                itemType: action.itemType,
                                name: action.name,
                                hours: action.hours,
                                hourRate: action.hourRate,
                                materials: action.materials,
                                materialsRate: action.materialsRate,
                                schedule: null,
                                colour: "",
                                originalMaterialsRate:
                                    find(
                                        finishSchedules,
                                        (finishSchedule) =>
                                            finishSchedule.name ===
                                                action.finishSchedule &&
                                            finishSchedule.substrates.indexOf(
                                                find(
                                                    itemTypes,
                                                    (itemType) =>
                                                        itemType.id.uuid ==
                                                        action.itemType
                                                )?.substrate || null
                                            ) !== -1
                                    )?.rate || null,
                            },
                            (x, y) => ({
                                ...x,
                                hours: x.hours.times(y),
                                materials: x.materials.times(y),
                            })
                        )
                    ),
                });
                for (const contingency of option.details.contingencies) {
                    contingencyItems.push(
                        ...schedulify(
                            option,
                            contingency.id.uuid,
                            (s) => s.contingencies,
                            sourceToProjectContingency(contingency, option),
                            (x, r) => ({
                                ...x,
                                quantity: x.quantity.times(r),
                            })
                        )
                    );
                }
            }
        }

        return resolveDetailSheetSchedules({
            ...detailSheet,
            revamped: true,
            initialized: true,
            options,
            schedules,
            ...(detailSheet.change
                ? {
                      projectDescription:
                          quotations[0]?.projectDescription ||
                          project.projectDescription,
                      schedulesDividedDescription: false,
                      contingencyItems,
                  }
                : {
                      contingencyItems: project.projectContingencyItems.flatMap(
                          (item) =>
                              schedulify(
                                  quotations
                                      .flatMap(
                                          (quotation) => quotation!.options
                                      )
                                      .find(
                                          (option) =>
                                              option.id.uuid ===
                                              item.originalOptionId
                                      ),
                                  item.originalId,
                                  (s) => s.contingencies,
                                  {
                                      ...item,
                                      id: newUUID(),
                                  },
                                  (x, r) => ({
                                      ...x,
                                      quantity: x.quantity.times(r),
                                  })
                              )
                      ),
                      schedulesDividedDescription:
                          project.projectSchedulesDividedDescription,
                      description: project.projectDescription,
                  }),
            scopeOfWork: quotations.flatMap(
                (quotation) => quotation!.scopeOfWork
            ),
            contractNotes: quotations.flatMap(
                (quotation) => quotation!.contractNotes
            ),
        });
    },
    pages(detailSheet) {
        return [
            {
                id: "main",
                title: "Personnel",
                widget: DetailSheetMainWidget,
            },
            {
                id: "scope-of-work",
                title: "Scope of Work",
                widget: DetailSheetScopeOfWorkWidget,
            },
            {
                id: "finish-schedules",
                title: "Finish Schedule(s)",
                widget: DetailSheetFinishSchedulesWidget,
            },
            {
                id: "contract-notes",
                title: "Contract Notes",
                widget: DetailSheetContractNotesWidget,
            },
            {
                id: "options",
                title: "Budgets",
                widget: DetailSheetOptionsWidget,
            },

            {
                id: "allowances",
                title: "Allowances",
                widget: DetailSheetAllowancesWidget,
            },
            {
                id: "contingency-items",
                title: "Contingency Items",
                widget: ContingencyItemTab,
            },
            {
                id: "non-cf-expenses",
                title: "Non-CF Expenses",
                widget: NonCfExpensesTab,
            },
            {
                id: "tm-rates",
                title: "T&M Rates",
                widget: TimeAndMaterialsRateTab,
            },
            {
                id: "contract-details",
                title: "Invoicing Details",
                widget: DetailSheetContractDetailsWidget,
            },
            {
                id: "admin",
                title: "Admin",
                widget: AdminTab,
                admin: true,
            },
        ];
    },
});

export const DetailSheetWidget: typeof DetailSheetWidgetBase = {
    ...DetailSheetWidgetBase,
    reduce(state, data, action, context) {
        const inner = DetailSheetWidgetBase.reduce(
            state,
            data,
            action,
            context
        );

        if (inner.data.revamped) {
            const lines = scheduleLines(inner.data);
            return {
                data: {
                    ...inner.data,
                    schedules: inner.data.schedules.map((schedule) => ({
                        ...schedule,
                        certifiedForemanContractAmount: sumMap(
                            lines.filter(
                                (line) =>
                                    line.schedule === schedule.id.uuid &&
                                    !line.nonCfExpense
                            ),
                            (line) => line.amount
                        ),
                    })),
                },
                state: inner.state,
            };
        }

        return inner;
    },
    validate(data, cache) {
        const inner = DetailSheetWidgetBase.validate(data, cache);
        if (!data.revamped) {
            const rebuildErrors = (
                errors: ValidationError[],
                f: (error: ValidationError) => ValidationError | null
            ) => {
                const newErrors = [];
                for (const error of errors) {
                    const newError = f(error);
                    if (newError) {
                        newErrors.push(newError);
                    }
                }
                return newErrors;
            };

            const rebuildError = (
                error: ValidationError,
                f: (error: ValidationError) => ValidationError | null
            ) => {
                if (error.detail) {
                    const detail = rebuildErrors(error.detail, f);
                    if (detail.length == 0) {
                        return null;
                    }
                    return {
                        ...error,
                        detail,
                    };
                } else {
                    return error;
                }
            };

            return rebuildErrors(inner, (error) => {
                switch (error.field) {
                    case "contingency-items":
                        return rebuildError(error, (error) => {
                            switch (error.field) {
                                case "contingencyItems":
                                    return rebuildError(error, (error) => {
                                        return rebuildError(error, (error) => {
                                            if (error.field === "schedule") {
                                                return null;
                                            } else {
                                                return error;
                                            }
                                        });
                                    });
                                default:
                                    return error;
                            }
                        });
                    case "options":
                        return rebuildError(error, (error) => {
                            switch (error.field) {
                                case "options":
                                    return rebuildError(error, (error) => {
                                        return rebuildError(error, (error) => {
                                            if (error.field === "budget") {
                                                return rebuildError(
                                                    error,
                                                    (error) => {
                                                        return rebuildError(
                                                            error,
                                                            (error) => {
                                                                if (
                                                                    error.field ===
                                                                    "schedule"
                                                                ) {
                                                                    return null;
                                                                } else {
                                                                    return error;
                                                                }
                                                            }
                                                        );
                                                    }
                                                );
                                            } else {
                                                return error;
                                            }
                                        });
                                    });
                                default:
                                    return error;
                            }
                        });
                    case "allowances":
                        return rebuildError(error, (error) => {
                            switch (error.field) {
                                case "options":
                                    return rebuildError(error, (error) => {
                                        return rebuildError(error, (error) => {
                                            if (error.field === "allowances") {
                                                return rebuildError(
                                                    error,
                                                    (error) => {
                                                        return rebuildError(
                                                            error,
                                                            (error) => {
                                                                if (
                                                                    error.field ===
                                                                    "schedule"
                                                                ) {
                                                                    return null;
                                                                } else {
                                                                    return error;
                                                                }
                                                            }
                                                        );
                                                    }
                                                );
                                            } else {
                                                return error;
                                            }
                                        });
                                    });
                                default:
                                    return error;
                            }
                        });
                    default:
                        return error;
                }
            });
        }

        return inner;
    },
};

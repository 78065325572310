import { every } from "lodash";
import { PaginatedWidget } from "../../../clay/paginated-widget";
import { newUUID } from "../../../clay/uuid";
import { QUOTATION_META } from "../../quotation/table";
import { Project } from "../table";
import AccessTab from "./AccessTab.widget";
import ClientSuccessTab from "./ClientSuccessTab.widget";
import ConcernsTab from "./ConcernsTab.widget";
import ContractNotesTab from "./ContractNotesTab.widget";
import DescriptionTab from "./DescriptionTab.widget";
import FinishScheduleTab from "./FinishScheduleTabWidget.widget";
import MobilizationTab from "./MobilizationTab.widget";
import PersonnelTab from "./PersonnellTab.widget";
import ProjectDetailsTab from "./ProjectDetailsTab.widget";
import ScopeOfWorkTab from "./ScopeOfWorkTab.widget";
import { HANDOFF_NOTE_META } from "./table";

export const HandOffWidget = PaginatedWidget({
    dataMeta: HANDOFF_NOTE_META,
    validate(handOffNote, cache, errors) {
        if (handOffNote.date) {
            return [];
        } else {
            return errors;
        }
    },
    process(handOffNote, cache, pageId, project: Project) {
        if (handOffNote.initialized) {
            return null;
        }

        const quotations = handOffNote.quotations.map((quotation) =>
            cache.get(QUOTATION_META, quotation)
        );

        if (pageId === "main") {
            return null;
        }

        if (!every(quotations)) {
            return undefined;
        }

        return {
            ...handOffNote,
            initialized: true,
            scopeOfWork: quotations.flatMap(
                (quotation) => quotation!.scopeOfWork
            ),
            contractNotes: quotations.flatMap(
                (quotation) => quotation!.contractNotes
            ),
            finishSchedule: quotations
                .flatMap((quotation) => quotation!.options)
                .flatMap((option) =>
                    option.details.finishSchedule.map((schedule) => ({
                        ...schedule,
                        id: newUUID(),
                        colour: "",
                    }))
                ),
        };
    },
    pages(handOffNotes) {
        return [
            {
                id: "personnel",
                widget: PersonnelTab,
                title: "Personnel",
            },
            {
                id: "project-description",
                widget: DescriptionTab,
                title: "Project Description",
            },
            {
                id: "project-details",
                widget: ProjectDetailsTab,
                title: "Project Details",
            },
            {
                id: "scope-of-work",
                widget: ScopeOfWorkTab,
                title: "Scope(s) for Work",
            },
            {
                id: "finish-schedule",
                widget: FinishScheduleTab,
                title: "Finish Schedule(s)",
            },
            {
                id: "contract-notes",
                widget: ContractNotesTab,
                title: "Contract Notes",
            },
            {
                id: "client-success",
                widget: ClientSuccessTab,
                title: "Client Success",
            },
            {
                id: "access",
                widget: AccessTab,
                title: "Access",
            },
            {
                id: "mobilization",
                widget: MobilizationTab,
                title: "Mobilization",
            },
            {
                id: "concerns",
                widget: ConcernsTab,
                title: "Concerns",
            },
        ];
    },
});

import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Button } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { RemoveButton } from "../../../clay/remove-button";
import { DropdownLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import { Optional } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { LinkSetWidget } from "../../../clay/widgets/link-set-widget";
import { useListItemContext } from "../../../clay/widgets/ListWidget";
import { MoneyStatic, MoneyWidget } from "../../../clay/widgets/money-widget";
import { PercentageWidget } from "../../../clay/widgets/percentage-widget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { MasterFormatLinkWidget } from "../../project/master-format-codes/link";
import { SPECTRUM_INVOICE_TYPE_META } from "../../project/spectrum/table";
import { AREA_META } from "../area/table";
import { ReactContext as EstimateWidgetReactContext } from "../EstimateWidget.widget";
import { Allowance, allowancePrice, ALLOWANCE_META } from "./table";

export type Data = Allowance;

export const Fields = {
    name: TextAreaWidget,
    masterFormatCode: MasterFormatLinkWidget,
    cost: Optional(MoneyWidget),
    markup: Optional(PercentageWidget),
    areas: Optional(
        LinkSetWidget({
            meta: AREA_META,
            name: (area) => area.name,
        })
    ),
    nonCfExpense: SwitchWidget,
    spectrumType: DropdownLinkWidget({
        meta: SPECTRUM_INVOICE_TYPE_META,
        label(data) {
            return data.code;
        },
    }),
};

export type ExtraProps = {
    onDuplicate: () => void;
};

function Component(props: Props) {
    const estimateContext = React.useContext(EstimateWidgetReactContext);
    if (!estimateContext) {
        throw new Error("Should be used in context");
    }
    const listItemContext = useListItemContext();

    return (
        <tr {...listItemContext.draggableProps}>
            <td>{listItemContext.dragHandle} </td>
            <td>
                <widgets.name />
            </td>
            <td>
                <widgets.masterFormatCode />
            </td>
            <td>
                <widgets.spectrumType />
            </td>
            <td>
                <widgets.cost />
            </td>
            <td>
                <widgets.markup />
            </td>
            <td>
                <MoneyStatic
                    value={allowancePrice(
                        props.data,
                        estimateContext.data.common
                            .additionalAllowancesMarkup ??
                            estimateContext.data.common.additionalMarkup
                    )}
                />
            </td>
            <td style={{ textAlign: "center" }}>
                <widgets.nonCfExpense />
            </td>
            <td>
                <widgets.areas
                    horizontal
                    records={estimateContext.data.areas}
                />
            </td>
            <td>
                <Button onClick={props.onDuplicate}>
                    <FontAwesomeIcon icon={faCopy} />
                </Button>
            </td>
            <td>
                <RemoveButton />
            </td>
        </tr>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.name> &
    WidgetContext<typeof Fields.masterFormatCode> &
    WidgetContext<typeof Fields.cost> &
    WidgetContext<typeof Fields.markup> &
    WidgetContext<typeof Fields.areas> &
    WidgetContext<typeof Fields.nonCfExpense> &
    WidgetContext<typeof Fields.spectrumType>;
type BaseState = {
    name: WidgetState<typeof Fields.name>;
    masterFormatCode: WidgetState<typeof Fields.masterFormatCode>;
    cost: WidgetState<typeof Fields.cost>;
    markup: WidgetState<typeof Fields.markup>;
    areas: WidgetState<typeof Fields.areas>;
    nonCfExpense: WidgetState<typeof Fields.nonCfExpense>;
    spectrumType: WidgetState<typeof Fields.spectrumType>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "NAME"; action: WidgetAction<typeof Fields.name> }
    | {
          type: "MASTER_FORMAT_CODE";
          action: WidgetAction<typeof Fields.masterFormatCode>;
      }
    | { type: "COST"; action: WidgetAction<typeof Fields.cost> }
    | { type: "MARKUP"; action: WidgetAction<typeof Fields.markup> }
    | { type: "AREAS"; action: WidgetAction<typeof Fields.areas> }
    | {
          type: "NON_CF_EXPENSE";
          action: WidgetAction<typeof Fields.nonCfExpense>;
      }
    | {
          type: "SPECTRUM_TYPE";
          action: WidgetAction<typeof Fields.spectrumType>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps> & ExtraProps;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.name, data.name, cache, "name", errors);
    subvalidate(
        Fields.masterFormatCode,
        data.masterFormatCode,
        cache,
        "masterFormatCode",
        errors
    );
    subvalidate(Fields.cost, data.cost, cache, "cost", errors);
    subvalidate(Fields.markup, data.markup, cache, "markup", errors);
    subvalidate(Fields.areas, data.areas, cache, "areas", errors);
    subvalidate(
        Fields.nonCfExpense,
        data.nonCfExpense,
        cache,
        "nonCfExpense",
        errors
    );
    subvalidate(
        Fields.spectrumType,
        data.spectrumType,
        cache,
        "spectrumType",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "NAME": {
            const inner = Fields.name.reduce(
                state.name,
                data.name,
                action.action,
                subcontext
            );
            return {
                state: { ...state, name: inner.state },
                data: { ...data, name: inner.data },
            };
        }
        case "MASTER_FORMAT_CODE": {
            const inner = Fields.masterFormatCode.reduce(
                state.masterFormatCode,
                data.masterFormatCode,
                action.action,
                subcontext
            );
            return {
                state: { ...state, masterFormatCode: inner.state },
                data: { ...data, masterFormatCode: inner.data },
            };
        }
        case "COST": {
            const inner = Fields.cost.reduce(
                state.cost,
                data.cost,
                action.action,
                subcontext
            );
            return {
                state: { ...state, cost: inner.state },
                data: { ...data, cost: inner.data },
            };
        }
        case "MARKUP": {
            const inner = Fields.markup.reduce(
                state.markup,
                data.markup,
                action.action,
                subcontext
            );
            return {
                state: { ...state, markup: inner.state },
                data: { ...data, markup: inner.data },
            };
        }
        case "AREAS": {
            const inner = Fields.areas.reduce(
                state.areas,
                data.areas,
                action.action,
                subcontext
            );
            return {
                state: { ...state, areas: inner.state },
                data: { ...data, areas: inner.data },
            };
        }
        case "NON_CF_EXPENSE": {
            const inner = Fields.nonCfExpense.reduce(
                state.nonCfExpense,
                data.nonCfExpense,
                action.action,
                subcontext
            );
            return {
                state: { ...state, nonCfExpense: inner.state },
                data: { ...data, nonCfExpense: inner.data },
            };
        }
        case "SPECTRUM_TYPE": {
            const inner = Fields.spectrumType.reduce(
                state.spectrumType,
                data.spectrumType,
                action.action,
                subcontext
            );
            return {
                state: { ...state, spectrumType: inner.state },
                data: { ...data, spectrumType: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    name: function (
        props: WidgetExtraProps<typeof Fields.name> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "NAME", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "name", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.name.component
                state={context.state.name}
                data={context.data.name}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Name"}
            />
        );
    },
    masterFormatCode: function (
        props: WidgetExtraProps<typeof Fields.masterFormatCode> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MASTER_FORMAT_CODE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "masterFormatCode", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.masterFormatCode.component
                state={context.state.masterFormatCode}
                data={context.data.masterFormatCode}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Master Format Code"}
            />
        );
    },
    cost: function (
        props: WidgetExtraProps<typeof Fields.cost> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "COST", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "cost", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.cost.component
                state={context.state.cost}
                data={context.data.cost}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Cost"}
            />
        );
    },
    markup: function (
        props: WidgetExtraProps<typeof Fields.markup> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MARKUP",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "markup", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.markup.component
                state={context.state.markup}
                data={context.data.markup}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Markup"}
            />
        );
    },
    areas: function (
        props: WidgetExtraProps<typeof Fields.areas> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "AREAS", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "areas", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.areas.component
                state={context.state.areas}
                data={context.data.areas}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Areas"}
            />
        );
    },
    nonCfExpense: function (
        props: WidgetExtraProps<typeof Fields.nonCfExpense> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "NON_CF_EXPENSE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "nonCfExpense", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.nonCfExpense.component
                state={context.state.nonCfExpense}
                data={context.data.nonCfExpense}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Non Cf Expense"}
            />
        );
    },
    spectrumType: function (
        props: WidgetExtraProps<typeof Fields.spectrumType> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SPECTRUM_TYPE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "spectrumType", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.spectrumType.component
                state={context.state.spectrumType}
                data={context.data.spectrumType}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Spectrum Type"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: ALLOWANCE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let nameState;
        {
            const inner = Fields.name.initialize(
                data.name,
                subcontext,
                subparameters.name
            );
            nameState = inner.state;
            data = { ...data, name: inner.data };
        }
        let masterFormatCodeState;
        {
            const inner = Fields.masterFormatCode.initialize(
                data.masterFormatCode,
                subcontext,
                subparameters.masterFormatCode
            );
            masterFormatCodeState = inner.state;
            data = { ...data, masterFormatCode: inner.data };
        }
        let costState;
        {
            const inner = Fields.cost.initialize(
                data.cost,
                subcontext,
                subparameters.cost
            );
            costState = inner.state;
            data = { ...data, cost: inner.data };
        }
        let markupState;
        {
            const inner = Fields.markup.initialize(
                data.markup,
                subcontext,
                subparameters.markup
            );
            markupState = inner.state;
            data = { ...data, markup: inner.data };
        }
        let areasState;
        {
            const inner = Fields.areas.initialize(
                data.areas,
                subcontext,
                subparameters.areas
            );
            areasState = inner.state;
            data = { ...data, areas: inner.data };
        }
        let nonCfExpenseState;
        {
            const inner = Fields.nonCfExpense.initialize(
                data.nonCfExpense,
                subcontext,
                subparameters.nonCfExpense
            );
            nonCfExpenseState = inner.state;
            data = { ...data, nonCfExpense: inner.data };
        }
        let spectrumTypeState;
        {
            const inner = Fields.spectrumType.initialize(
                data.spectrumType,
                subcontext,
                subparameters.spectrumType
            );
            spectrumTypeState = inner.state;
            data = { ...data, spectrumType: inner.data };
        }
        let state = {
            initialParameters: parameters,
            name: nameState,
            masterFormatCode: masterFormatCodeState,
            cost: costState,
            markup: markupState,
            areas: areasState,
            nonCfExpense: nonCfExpenseState,
            spectrumType: spectrumTypeState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={ALLOWANCE_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    name: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.name>
    >;
    masterFormatCode: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.masterFormatCode>
    >;
    cost: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.cost>
    >;
    markup: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.markup>
    >;
    areas: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.areas>
    >;
    nonCfExpense: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.nonCfExpense>
    >;
    spectrumType: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.spectrumType>
    >;
};
// END MAGIC -- DO NOT EDIT

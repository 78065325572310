import React from "react";
import { Button, Form } from "react-bootstrap";
import { patchRecord } from "../../clay/api";
import { sumMap } from "../../clay/queryFuncs";
import { useQuickCache, useQuickRecord } from "../../clay/quick-cache";
import { FormWrapper } from "../../clay/widgets/FormField";
import { StaticTextField } from "../../clay/widgets/TextWidget";
import { calcAddressLineFormatted } from "../address";
import { formatMoney } from "../estimate/TotalsWidget.widget";
import { calcProjectSummary, PROJECT_META } from "../project/table";
import { QUOTATION_META } from "../quotation/table";
import { TABLE_LEFT_STYLE } from "../styles";
import { ROLE_ESTIMATOR, USER_META } from "../user/table";
import { MessageBody, MessageFooter, MessageHeader } from "./message";
import NotificationSource from "./NotificationSource";
import { ITEM_TYPE } from "./types";

function Component(props: {
    id: string;
    setOpenItem: (item: ITEM_TYPE | null) => void;
}) {
    const project = useQuickRecord(PROJECT_META, props.id || null);
    const quotation = useQuickRecord(
        QUOTATION_META,
        project?.selectedQuotation || null
    );
    const cache = useQuickCache();

    const onDismiss = React.useCallback(() => {
        patchRecord(PROJECT_META, "inbox", props.id, {
            acknowledgedLarge: [false, true],
        });
        props.setOpenItem(null);
    }, [props.id, props.setOpenItem]);

    if (!quotation || !project) {
        return <></>;
    }

    return (
        <>
            <MessageHeader>
                {calcProjectSummary(project)} Large Project Awarded
            </MessageHeader>
            <MessageBody>
                <FormWrapper label="Client's Name">
                    <StaticTextField value={project.customer} />
                </FormWrapper>
                <FormWrapper label="Site Address">
                    <StaticTextField
                        value={calcAddressLineFormatted(project.siteAddress)}
                    />
                </FormWrapper>
                {project.personnel
                    .filter((entry) => entry.role == ROLE_ESTIMATOR)
                    .map((entry) => (
                        <FormWrapper label="Estimator" key={entry.user}>
                            <StaticTextField
                                value={
                                    cache.get(USER_META, entry.user)?.name || ""
                                }
                            />
                        </FormWrapper>
                    ))}

                <FormWrapper label="Expected Contract Value Includes">
                    <table {...TABLE_LEFT_STYLE}>
                        <thead>
                            <tr>
                                <th style={{ width: "10em" }}>Name</th>
                                <th>Description</th>
                                <th style={{ width: "10em" }}>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {quotation.options.map((option, optionIndex) => (
                                <tr>
                                    <td>
                                        <Form.Check
                                            style={{ display: "inline-block" }}
                                            className="checkbox-widget"
                                            type="checkbox"
                                            checked={
                                                option.includedInExpectedContractValue
                                            }
                                            disabled={true}
                                        />{" "}
                                        {option.name}
                                    </td>
                                    <td>{option.description}</td>
                                    <td>{formatMoney(option.details.total)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total</th>
                                <th />
                                <th>
                                    {formatMoney(
                                        sumMap(
                                            quotation.options.filter(
                                                (option) =>
                                                    option.includedInExpectedContractValue
                                            ),
                                            (option) => option.details.total
                                        )
                                    )}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </FormWrapper>
            </MessageBody>
            <MessageFooter>
                <Button disabled={false} variant="danger" onClick={onDismiss}>
                    Dismiss
                </Button>
                <Button
                    style={{ marginLeft: "1em", display: "block" }}
                    onClick={() => {
                        window.open(
                            "#/project/edit/" +
                                quotation.project +
                                "/quotations/"
                        );
                        props.setOpenItem(null);
                    }}
                >
                    Open Project
                </Button>
            </MessageFooter>
        </>
    );
}

export const LARGE_PROPOSAL_AWARDED_SOURCE = NotificationSource({
    key: "large-quotation",
    label: "Large Project Awarded",
    Component,
    table: PROJECT_META,
    priority: false,
    date(project) {
        return project.projectAwardDate;
    },
    active: "largeProjectAwarded",
    sendToUsersWithPermission: "Inbox-large-proposal-submitted",
});

import * as React from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { CirclePicker } from "react-color";
import { statusToState, Widget, WidgetStatus } from "./index";
import { SimpleAtomic } from "./simple-atomic";

export type ColorWidgetAction = {
    type: "SET";
    value: string;
};

type ColorWidgetProps = {
    state: null;
    data: string;
    dispatch: (action: ColorWidgetAction) => void;
    status: WidgetStatus;
    style?: React.CSSProperties;
    hideStatus?: boolean;
    fallback?: string;
    clearable?: boolean;
};

export type ColorWidgetType = Widget<
    null,
    string,
    {},
    ColorWidgetAction,
    {
        fallback?: string;
        clearable?: boolean;
    }
>;

export const ColorWidget: ColorWidgetType = {
    ...SimpleAtomic,
    dataMeta: {
        type: "string",
    },
    initialize(data: string) {
        return {
            state: null,
            data,
        };
    },
    component({
        data,
        dispatch,
        status,
        state,
        style,
        fallback,
        hideStatus,
        clearable,
    }: ColorWidgetProps) {
        const clickReset = React.useCallback(() => {
            dispatch({
                type: "SET",
                value: "",
            });
        }, [dispatch]);
        return (
            <InputGroup>
                <CirclePicker
                    color={data || fallback || ""}
                    onChange={(color) =>
                        dispatch({
                            type: "SET",
                            value: color.hex,
                        })
                    }
                    className={
                        hideStatus
                            ? ""
                            : statusToState(status.validation, data === "")
                    }
                />
                {clearable && (
                    <InputGroup.Append
                        style={{
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Button
                            onClick={clickReset}
                            style={{ marginLeft: "2em" }}
                        >
                            Reset
                        </Button>
                    </InputGroup.Append>
                )}
            </InputGroup>
        );
    },
    reduce(state: null, data: string, action: ColorWidgetAction, context: {}) {
        switch (action.type) {
            case "SET":
                return {
                    state: null,
                    data: action.value,
                };
        }
    },
    validate(data: string) {
        if (data !== "") {
            return [];
        } else {
            return [
                {
                    invalid: false,
                    empty: true,
                },
            ];
        }
    },
};

export function StaticTextField(props: { value: string }) {
    return <FormControl type="text" disabled={true} value={props.value} />;
}

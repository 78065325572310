import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { PageContext } from "../../../clay/Page";
import { propCheck } from "../../../clay/propCheck";
import {
    QuickCacheApi,
    useQuickCache,
    useQuickRecord,
} from "../../../clay/quick-cache";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { COMPANY_META } from "../../company/table";
import { useUser } from "../../state";
import { USER_META } from "../../user/table";
import { Project, PROJECT_META } from "../table";

import { DateTimeWidget } from "../../../clay/widgets/DateTimeWidget";
import { FieldRow } from "../../../clay/widgets/layout";
import { SelectWidget } from "../../../clay/widgets/SelectWidget";
import { ThirdPartySpecifierLinkWidget } from "../types/link";

export type Data = Project;
export const Fields = {
    projectNameOrNumber: FormField(TextWidget),
    tenderDetailsProjectDetails: FormField(TextAreaWidget),
    tenderDue: FormField(DateTimeWidget),
    tenderDeliveryMethod: FormField(
        SelectWidget([
            {
                value: "email" as const,
                label: "Email",
            },
            {
                value: "hard-copy" as const,
                label: "Hard Copy",
            },
        ])
    ),
    thirdPartySpecifierInvolved: OptionalFormField(
        ThirdPartySpecifierLinkWidget
    ),
};

type Context = PageContext;

function Component(props: Props) {
    const user = useUser();
    const self = useQuickRecord(USER_META, user.id);
    const cache = useQuickCache();
    for (const billingContact of props.data.billingContacts) {
        cache.get(COMPANY_META, billingContact.company.company);
    }

    return (
        <>
            <widgets.projectNameOrNumber />
            <widgets.tenderDetailsProjectDetails label="Project Details" />
            <FieldRow>
                <widgets.tenderDue />
                <widgets.tenderDeliveryMethod label="Delivery Method" />
            </FieldRow>
            <widgets.thirdPartySpecifierInvolved
                label="Third-Party Specifier Involved?"
                clearable
            />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type SubContext = WidgetContext<typeof Fields.projectNameOrNumber> &
    WidgetContext<typeof Fields.tenderDetailsProjectDetails> &
    WidgetContext<typeof Fields.tenderDue> &
    WidgetContext<typeof Fields.tenderDeliveryMethod> &
    WidgetContext<typeof Fields.thirdPartySpecifierInvolved>;
type ExtraProps = {};
type BaseState = {
    projectNameOrNumber: WidgetState<typeof Fields.projectNameOrNumber>;
    tenderDetailsProjectDetails: WidgetState<
        typeof Fields.tenderDetailsProjectDetails
    >;
    tenderDue: WidgetState<typeof Fields.tenderDue>;
    tenderDeliveryMethod: WidgetState<typeof Fields.tenderDeliveryMethod>;
    thirdPartySpecifierInvolved: WidgetState<
        typeof Fields.thirdPartySpecifierInvolved
    >;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "PROJECT_NAME_OR_NUMBER";
          action: WidgetAction<typeof Fields.projectNameOrNumber>;
      }
    | {
          type: "TENDER_DETAILS_PROJECT_DETAILS";
          action: WidgetAction<typeof Fields.tenderDetailsProjectDetails>;
      }
    | { type: "TENDER_DUE"; action: WidgetAction<typeof Fields.tenderDue> }
    | {
          type: "TENDER_DELIVERY_METHOD";
          action: WidgetAction<typeof Fields.tenderDeliveryMethod>;
      }
    | {
          type: "THIRD_PARTY_SPECIFIER_INVOLVED";
          action: WidgetAction<typeof Fields.thirdPartySpecifierInvolved>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.projectNameOrNumber,
        data.projectNameOrNumber,
        cache,
        "projectNameOrNumber",
        errors
    );
    subvalidate(
        Fields.tenderDetailsProjectDetails,
        data.tenderDetailsProjectDetails,
        cache,
        "tenderDetailsProjectDetails",
        errors
    );
    subvalidate(Fields.tenderDue, data.tenderDue, cache, "tenderDue", errors);
    subvalidate(
        Fields.tenderDeliveryMethod,
        data.tenderDeliveryMethod,
        cache,
        "tenderDeliveryMethod",
        errors
    );
    subvalidate(
        Fields.thirdPartySpecifierInvolved,
        data.thirdPartySpecifierInvolved,
        cache,
        "thirdPartySpecifierInvolved",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "PROJECT_NAME_OR_NUMBER": {
            const inner = Fields.projectNameOrNumber.reduce(
                state.projectNameOrNumber,
                data.projectNameOrNumber,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectNameOrNumber: inner.state },
                data: { ...data, projectNameOrNumber: inner.data },
            };
        }
        case "TENDER_DETAILS_PROJECT_DETAILS": {
            const inner = Fields.tenderDetailsProjectDetails.reduce(
                state.tenderDetailsProjectDetails,
                data.tenderDetailsProjectDetails,
                action.action,
                subcontext
            );
            return {
                state: { ...state, tenderDetailsProjectDetails: inner.state },
                data: { ...data, tenderDetailsProjectDetails: inner.data },
            };
        }
        case "TENDER_DUE": {
            const inner = Fields.tenderDue.reduce(
                state.tenderDue,
                data.tenderDue,
                action.action,
                subcontext
            );
            return {
                state: { ...state, tenderDue: inner.state },
                data: { ...data, tenderDue: inner.data },
            };
        }
        case "TENDER_DELIVERY_METHOD": {
            const inner = Fields.tenderDeliveryMethod.reduce(
                state.tenderDeliveryMethod,
                data.tenderDeliveryMethod,
                action.action,
                subcontext
            );
            return {
                state: { ...state, tenderDeliveryMethod: inner.state },
                data: { ...data, tenderDeliveryMethod: inner.data },
            };
        }
        case "THIRD_PARTY_SPECIFIER_INVOLVED": {
            const inner = Fields.thirdPartySpecifierInvolved.reduce(
                state.thirdPartySpecifierInvolved,
                data.thirdPartySpecifierInvolved,
                action.action,
                subcontext
            );
            return {
                state: { ...state, thirdPartySpecifierInvolved: inner.state },
                data: { ...data, thirdPartySpecifierInvolved: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    projectNameOrNumber: function (
        props: WidgetExtraProps<typeof Fields.projectNameOrNumber> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_NAME_OR_NUMBER",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectNameOrNumber",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectNameOrNumber.component
                state={context.state.projectNameOrNumber}
                data={context.data.projectNameOrNumber}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Name or Number"}
            />
        );
    },
    tenderDetailsProjectDetails: function (
        props: WidgetExtraProps<typeof Fields.tenderDetailsProjectDetails> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "TENDER_DETAILS_PROJECT_DETAILS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "tenderDetailsProjectDetails",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.tenderDetailsProjectDetails.component
                state={context.state.tenderDetailsProjectDetails}
                data={context.data.tenderDetailsProjectDetails}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Tender Details Project Details"}
            />
        );
    },
    tenderDue: function (
        props: WidgetExtraProps<typeof Fields.tenderDue> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "TENDER_DUE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "tenderDue", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.tenderDue.component
                state={context.state.tenderDue}
                data={context.data.tenderDue}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Tender Due"}
            />
        );
    },
    tenderDeliveryMethod: function (
        props: WidgetExtraProps<typeof Fields.tenderDeliveryMethod> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "TENDER_DELIVERY_METHOD",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "tenderDeliveryMethod",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.tenderDeliveryMethod.component
                state={context.state.tenderDeliveryMethod}
                data={context.data.tenderDeliveryMethod}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Tender Delivery Method"}
            />
        );
    },
    thirdPartySpecifierInvolved: function (
        props: WidgetExtraProps<typeof Fields.thirdPartySpecifierInvolved> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "THIRD_PARTY_SPECIFIER_INVOLVED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "thirdPartySpecifierInvolved",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.thirdPartySpecifierInvolved.component
                state={context.state.thirdPartySpecifierInvolved}
                data={context.data.thirdPartySpecifierInvolved}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Third Party Specifier Involved"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: PROJECT_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let projectNameOrNumberState;
        {
            const inner = Fields.projectNameOrNumber.initialize(
                data.projectNameOrNumber,
                subcontext,
                subparameters.projectNameOrNumber
            );
            projectNameOrNumberState = inner.state;
            data = { ...data, projectNameOrNumber: inner.data };
        }
        let tenderDetailsProjectDetailsState;
        {
            const inner = Fields.tenderDetailsProjectDetails.initialize(
                data.tenderDetailsProjectDetails,
                subcontext,
                subparameters.tenderDetailsProjectDetails
            );
            tenderDetailsProjectDetailsState = inner.state;
            data = { ...data, tenderDetailsProjectDetails: inner.data };
        }
        let tenderDueState;
        {
            const inner = Fields.tenderDue.initialize(
                data.tenderDue,
                subcontext,
                subparameters.tenderDue
            );
            tenderDueState = inner.state;
            data = { ...data, tenderDue: inner.data };
        }
        let tenderDeliveryMethodState;
        {
            const inner = Fields.tenderDeliveryMethod.initialize(
                data.tenderDeliveryMethod,
                subcontext,
                subparameters.tenderDeliveryMethod
            );
            tenderDeliveryMethodState = inner.state;
            data = { ...data, tenderDeliveryMethod: inner.data };
        }
        let thirdPartySpecifierInvolvedState;
        {
            const inner = Fields.thirdPartySpecifierInvolved.initialize(
                data.thirdPartySpecifierInvolved,
                subcontext,
                subparameters.thirdPartySpecifierInvolved
            );
            thirdPartySpecifierInvolvedState = inner.state;
            data = { ...data, thirdPartySpecifierInvolved: inner.data };
        }
        let state = {
            initialParameters: parameters,
            projectNameOrNumber: projectNameOrNumberState,
            tenderDetailsProjectDetails: tenderDetailsProjectDetailsState,
            tenderDue: tenderDueState,
            tenderDeliveryMethod: tenderDeliveryMethodState,
            thirdPartySpecifierInvolved: thirdPartySpecifierInvolvedState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={PROJECT_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    projectNameOrNumber: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectNameOrNumber>
    >;
    tenderDetailsProjectDetails: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.tenderDetailsProjectDetails>
    >;
    tenderDue: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.tenderDue>
    >;
    tenderDeliveryMethod: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.tenderDeliveryMethod>
    >;
    thirdPartySpecifierInvolved: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.thirdPartySpecifierInvolved>
    >;
};
// END MAGIC -- DO NOT EDIT

import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import {
    FormWrapper,
    OptionalFormField,
} from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import RequiredEquipmentWidget from "../detail-sheet/RequiredEquipmentWidget.widget";
import { HandoffNote, HANDOFF_NOTE_META } from "./table";

export type Data = HandoffNote;

export const Fields = {
    requiredEquipmentList: ListWidget(RequiredEquipmentWidget, {
        emptyOk: true,
    }),
    challengingLocations: OptionalFormField(TextAreaWidget),
};

function Component(props: Props) {
    return (
        <>
            <FormWrapper label="Required Equipment">
                <widgets.requiredEquipmentList extraItemForAdd />
            </FormWrapper>
            <widgets.challengingLocations />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.requiredEquipmentList> &
    WidgetContext<typeof Fields.challengingLocations>;
type ExtraProps = {};
type BaseState = {
    requiredEquipmentList: WidgetState<typeof Fields.requiredEquipmentList>;
    challengingLocations: WidgetState<typeof Fields.challengingLocations>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "REQUIRED_EQUIPMENT_LIST";
          action: WidgetAction<typeof Fields.requiredEquipmentList>;
      }
    | {
          type: "CHALLENGING_LOCATIONS";
          action: WidgetAction<typeof Fields.challengingLocations>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.requiredEquipmentList,
        data.requiredEquipmentList,
        cache,
        "requiredEquipmentList",
        errors
    );
    subvalidate(
        Fields.challengingLocations,
        data.challengingLocations,
        cache,
        "challengingLocations",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "REQUIRED_EQUIPMENT_LIST": {
            const inner = Fields.requiredEquipmentList.reduce(
                state.requiredEquipmentList,
                data.requiredEquipmentList,
                action.action,
                subcontext
            );
            return {
                state: { ...state, requiredEquipmentList: inner.state },
                data: { ...data, requiredEquipmentList: inner.data },
            };
        }
        case "CHALLENGING_LOCATIONS": {
            const inner = Fields.challengingLocations.reduce(
                state.challengingLocations,
                data.challengingLocations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, challengingLocations: inner.state },
                data: { ...data, challengingLocations: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    requiredEquipmentList: function (
        props: WidgetExtraProps<typeof Fields.requiredEquipmentList> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "REQUIRED_EQUIPMENT_LIST",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "requiredEquipmentList",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.requiredEquipmentList.component
                state={context.state.requiredEquipmentList}
                data={context.data.requiredEquipmentList}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Required Equipment List"}
            />
        );
    },
    challengingLocations: function (
        props: WidgetExtraProps<typeof Fields.challengingLocations> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CHALLENGING_LOCATIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "challengingLocations",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.challengingLocations.component
                state={context.state.challengingLocations}
                data={context.data.challengingLocations}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Challenging Locations"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: HANDOFF_NOTE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let requiredEquipmentListState;
        {
            const inner = Fields.requiredEquipmentList.initialize(
                data.requiredEquipmentList,
                subcontext,
                subparameters.requiredEquipmentList
            );
            requiredEquipmentListState = inner.state;
            data = { ...data, requiredEquipmentList: inner.data };
        }
        let challengingLocationsState;
        {
            const inner = Fields.challengingLocations.initialize(
                data.challengingLocations,
                subcontext,
                subparameters.challengingLocations
            );
            challengingLocationsState = inner.state;
            data = { ...data, challengingLocations: inner.data };
        }
        let state = {
            initialParameters: parameters,
            requiredEquipmentList: requiredEquipmentListState,
            challengingLocations: challengingLocationsState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={HANDOFF_NOTE_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    requiredEquipmentList: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.requiredEquipmentList>
    >;
    challengingLocations: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.challengingLocations>
    >;
};
// END MAGIC -- DO NOT EDIT

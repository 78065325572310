import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { GenerateButton } from "../../../clay/generate-button";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { FieldRow } from "../../../clay/widgets/layout";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { HandoffNote, HANDOFF_NOTE_META } from "./table";

export type Data = HandoffNote;

export const Fields = {
    potentialDelays: OptionalFormField(TextAreaWidget),
    colourApprovalProcess: OptionalFormField(TextAreaWidget),
    permitsRequired: OptionalFormField(SwitchWidget),
    permitsRequiredNote: OptionalFormField(TextAreaWidget),
    potentialBudgetChallenges: OptionalFormField(TextAreaWidget),
};

function Component(props: Props) {
    return (
        <>
            <widgets.potentialDelays />
            <widgets.colourApprovalProcess />
            <FieldRow>
                <widgets.permitsRequired />
                {props.data.permitsRequired && <widgets.permitsRequiredNote />}
            </FieldRow>
            <widgets.potentialBudgetChallenges />
            <GenerateButton />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.potentialDelays> &
    WidgetContext<typeof Fields.colourApprovalProcess> &
    WidgetContext<typeof Fields.permitsRequired> &
    WidgetContext<typeof Fields.permitsRequiredNote> &
    WidgetContext<typeof Fields.potentialBudgetChallenges>;
type ExtraProps = {};
type BaseState = {
    potentialDelays: WidgetState<typeof Fields.potentialDelays>;
    colourApprovalProcess: WidgetState<typeof Fields.colourApprovalProcess>;
    permitsRequired: WidgetState<typeof Fields.permitsRequired>;
    permitsRequiredNote: WidgetState<typeof Fields.permitsRequiredNote>;
    potentialBudgetChallenges: WidgetState<
        typeof Fields.potentialBudgetChallenges
    >;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "POTENTIAL_DELAYS";
          action: WidgetAction<typeof Fields.potentialDelays>;
      }
    | {
          type: "COLOUR_APPROVAL_PROCESS";
          action: WidgetAction<typeof Fields.colourApprovalProcess>;
      }
    | {
          type: "PERMITS_REQUIRED";
          action: WidgetAction<typeof Fields.permitsRequired>;
      }
    | {
          type: "PERMITS_REQUIRED_NOTE";
          action: WidgetAction<typeof Fields.permitsRequiredNote>;
      }
    | {
          type: "POTENTIAL_BUDGET_CHALLENGES";
          action: WidgetAction<typeof Fields.potentialBudgetChallenges>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.potentialDelays,
        data.potentialDelays,
        cache,
        "potentialDelays",
        errors
    );
    subvalidate(
        Fields.colourApprovalProcess,
        data.colourApprovalProcess,
        cache,
        "colourApprovalProcess",
        errors
    );
    subvalidate(
        Fields.permitsRequired,
        data.permitsRequired,
        cache,
        "permitsRequired",
        errors
    );
    subvalidate(
        Fields.permitsRequiredNote,
        data.permitsRequiredNote,
        cache,
        "permitsRequiredNote",
        errors
    );
    subvalidate(
        Fields.potentialBudgetChallenges,
        data.potentialBudgetChallenges,
        cache,
        "potentialBudgetChallenges",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "POTENTIAL_DELAYS": {
            const inner = Fields.potentialDelays.reduce(
                state.potentialDelays,
                data.potentialDelays,
                action.action,
                subcontext
            );
            return {
                state: { ...state, potentialDelays: inner.state },
                data: { ...data, potentialDelays: inner.data },
            };
        }
        case "COLOUR_APPROVAL_PROCESS": {
            const inner = Fields.colourApprovalProcess.reduce(
                state.colourApprovalProcess,
                data.colourApprovalProcess,
                action.action,
                subcontext
            );
            return {
                state: { ...state, colourApprovalProcess: inner.state },
                data: { ...data, colourApprovalProcess: inner.data },
            };
        }
        case "PERMITS_REQUIRED": {
            const inner = Fields.permitsRequired.reduce(
                state.permitsRequired,
                data.permitsRequired,
                action.action,
                subcontext
            );
            return {
                state: { ...state, permitsRequired: inner.state },
                data: { ...data, permitsRequired: inner.data },
            };
        }
        case "PERMITS_REQUIRED_NOTE": {
            const inner = Fields.permitsRequiredNote.reduce(
                state.permitsRequiredNote,
                data.permitsRequiredNote,
                action.action,
                subcontext
            );
            return {
                state: { ...state, permitsRequiredNote: inner.state },
                data: { ...data, permitsRequiredNote: inner.data },
            };
        }
        case "POTENTIAL_BUDGET_CHALLENGES": {
            const inner = Fields.potentialBudgetChallenges.reduce(
                state.potentialBudgetChallenges,
                data.potentialBudgetChallenges,
                action.action,
                subcontext
            );
            return {
                state: { ...state, potentialBudgetChallenges: inner.state },
                data: { ...data, potentialBudgetChallenges: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    potentialDelays: function (
        props: WidgetExtraProps<typeof Fields.potentialDelays> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "POTENTIAL_DELAYS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "potentialDelays", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.potentialDelays.component
                state={context.state.potentialDelays}
                data={context.data.potentialDelays}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Potential Delays"}
            />
        );
    },
    colourApprovalProcess: function (
        props: WidgetExtraProps<typeof Fields.colourApprovalProcess> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "COLOUR_APPROVAL_PROCESS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "colourApprovalProcess",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.colourApprovalProcess.component
                state={context.state.colourApprovalProcess}
                data={context.data.colourApprovalProcess}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Colour Approval Process"}
            />
        );
    },
    permitsRequired: function (
        props: WidgetExtraProps<typeof Fields.permitsRequired> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PERMITS_REQUIRED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "permitsRequired", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.permitsRequired.component
                state={context.state.permitsRequired}
                data={context.data.permitsRequired}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Permits Required"}
            />
        );
    },
    permitsRequiredNote: function (
        props: WidgetExtraProps<typeof Fields.permitsRequiredNote> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PERMITS_REQUIRED_NOTE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "permitsRequiredNote",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.permitsRequiredNote.component
                state={context.state.permitsRequiredNote}
                data={context.data.permitsRequiredNote}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Permits Required Note"}
            />
        );
    },
    potentialBudgetChallenges: function (
        props: WidgetExtraProps<typeof Fields.potentialBudgetChallenges> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "POTENTIAL_BUDGET_CHALLENGES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "potentialBudgetChallenges",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.potentialBudgetChallenges.component
                state={context.state.potentialBudgetChallenges}
                data={context.data.potentialBudgetChallenges}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Potential Budget Challenges"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: HANDOFF_NOTE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let potentialDelaysState;
        {
            const inner = Fields.potentialDelays.initialize(
                data.potentialDelays,
                subcontext,
                subparameters.potentialDelays
            );
            potentialDelaysState = inner.state;
            data = { ...data, potentialDelays: inner.data };
        }
        let colourApprovalProcessState;
        {
            const inner = Fields.colourApprovalProcess.initialize(
                data.colourApprovalProcess,
                subcontext,
                subparameters.colourApprovalProcess
            );
            colourApprovalProcessState = inner.state;
            data = { ...data, colourApprovalProcess: inner.data };
        }
        let permitsRequiredState;
        {
            const inner = Fields.permitsRequired.initialize(
                data.permitsRequired,
                subcontext,
                subparameters.permitsRequired
            );
            permitsRequiredState = inner.state;
            data = { ...data, permitsRequired: inner.data };
        }
        let permitsRequiredNoteState;
        {
            const inner = Fields.permitsRequiredNote.initialize(
                data.permitsRequiredNote,
                subcontext,
                subparameters.permitsRequiredNote
            );
            permitsRequiredNoteState = inner.state;
            data = { ...data, permitsRequiredNote: inner.data };
        }
        let potentialBudgetChallengesState;
        {
            const inner = Fields.potentialBudgetChallenges.initialize(
                data.potentialBudgetChallenges,
                subcontext,
                subparameters.potentialBudgetChallenges
            );
            potentialBudgetChallengesState = inner.state;
            data = { ...data, potentialBudgetChallenges: inner.data };
        }
        let state = {
            initialParameters: parameters,
            potentialDelays: potentialDelaysState,
            colourApprovalProcess: colourApprovalProcessState,
            permitsRequired: permitsRequiredState,
            permitsRequiredNote: permitsRequiredNoteState,
            potentialBudgetChallenges: potentialBudgetChallengesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={HANDOFF_NOTE_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    potentialDelays: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.potentialDelays>
    >;
    colourApprovalProcess: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.colourApprovalProcess>
    >;
    permitsRequired: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.permitsRequired>
    >;
    permitsRequiredNote: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.permitsRequiredNote>
    >;
    potentialBudgetChallenges: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.potentialBudgetChallenges>
    >;
};
// END MAGIC -- DO NOT EDIT

import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { simpleLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import { OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { FieldRow } from "../../../clay/widgets/layout";
import { LinkSetWidget } from "../../../clay/widgets/link-set-widget";
import { SwitchWidget } from "../../../clay/widgets/SwitchWidget";
import { TextAreaWidget } from "../../../clay/widgets/TextAreaWidget";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { PARKING_TYPE_META, REQUIRED_NOTICE_TYPE_META } from "../types/table";
import { HandoffNote, HANDOFF_NOTE_META } from "./table";

export type Data = HandoffNote;

export const Fields = {
    requiredNotices: OptionalFormField(
        LinkSetWidget({
            meta: REQUIRED_NOTICE_TYPE_META,
            name(record) {
                return record.name;
            },
            filter(record) {
                return record.active;
            },
        })
    ),
    siteStorage: OptionalFormField(TextAreaWidget),
    liftParkingRequired: OptionalFormField(SwitchWidget),
    liftOvernightParkingLocation: OptionalFormField(TextWidget),
    portableToiletRequired: OptionalFormField(SwitchWidget),
    portableToiletProposedLocation: OptionalFormField(TextWidget),
    locationOfOnSiteWashrooms: OptionalFormField(TextWidget),
    parking: OptionalFormField(simpleLinkWidget(PARKING_TYPE_META)),
    parkingNotes: OptionalFormField(TextAreaWidget),
};

function Component(props: Props) {
    return (
        <>
            <widgets.requiredNotices />
            <widgets.siteStorage />
            <FieldRow>
                <widgets.liftParkingRequired label="Lift Parking Required?" />
                {props.data.liftParkingRequired && (
                    <widgets.liftOvernightParkingLocation label="Overnight Parking Location" />
                )}
            </FieldRow>
            <FieldRow>
                <widgets.portableToiletRequired label="Portable Toilet Required?" />
                {props.data.portableToiletRequired ? (
                    <widgets.portableToiletProposedLocation label="Proposed Location" />
                ) : (
                    <widgets.locationOfOnSiteWashrooms label="Location of On-Site Washrooms" />
                )}
            </FieldRow>
            <FieldRow>
                <widgets.parking />
                <widgets.parkingNotes label="Notes" />
            </FieldRow>
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.requiredNotices> &
    WidgetContext<typeof Fields.siteStorage> &
    WidgetContext<typeof Fields.liftParkingRequired> &
    WidgetContext<typeof Fields.liftOvernightParkingLocation> &
    WidgetContext<typeof Fields.portableToiletRequired> &
    WidgetContext<typeof Fields.portableToiletProposedLocation> &
    WidgetContext<typeof Fields.locationOfOnSiteWashrooms> &
    WidgetContext<typeof Fields.parking> &
    WidgetContext<typeof Fields.parkingNotes>;
type ExtraProps = {};
type BaseState = {
    requiredNotices: WidgetState<typeof Fields.requiredNotices>;
    siteStorage: WidgetState<typeof Fields.siteStorage>;
    liftParkingRequired: WidgetState<typeof Fields.liftParkingRequired>;
    liftOvernightParkingLocation: WidgetState<
        typeof Fields.liftOvernightParkingLocation
    >;
    portableToiletRequired: WidgetState<typeof Fields.portableToiletRequired>;
    portableToiletProposedLocation: WidgetState<
        typeof Fields.portableToiletProposedLocation
    >;
    locationOfOnSiteWashrooms: WidgetState<
        typeof Fields.locationOfOnSiteWashrooms
    >;
    parking: WidgetState<typeof Fields.parking>;
    parkingNotes: WidgetState<typeof Fields.parkingNotes>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "REQUIRED_NOTICES";
          action: WidgetAction<typeof Fields.requiredNotices>;
      }
    | { type: "SITE_STORAGE"; action: WidgetAction<typeof Fields.siteStorage> }
    | {
          type: "LIFT_PARKING_REQUIRED";
          action: WidgetAction<typeof Fields.liftParkingRequired>;
      }
    | {
          type: "LIFT_OVERNIGHT_PARKING_LOCATION";
          action: WidgetAction<typeof Fields.liftOvernightParkingLocation>;
      }
    | {
          type: "PORTABLE_TOILET_REQUIRED";
          action: WidgetAction<typeof Fields.portableToiletRequired>;
      }
    | {
          type: "PORTABLE_TOILET_PROPOSED_LOCATION";
          action: WidgetAction<typeof Fields.portableToiletProposedLocation>;
      }
    | {
          type: "LOCATION_OF_ON_SITE_WASHROOMS";
          action: WidgetAction<typeof Fields.locationOfOnSiteWashrooms>;
      }
    | { type: "PARKING"; action: WidgetAction<typeof Fields.parking> }
    | {
          type: "PARKING_NOTES";
          action: WidgetAction<typeof Fields.parkingNotes>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.requiredNotices,
        data.requiredNotices,
        cache,
        "requiredNotices",
        errors
    );
    subvalidate(
        Fields.siteStorage,
        data.siteStorage,
        cache,
        "siteStorage",
        errors
    );
    subvalidate(
        Fields.liftParkingRequired,
        data.liftParkingRequired,
        cache,
        "liftParkingRequired",
        errors
    );
    subvalidate(
        Fields.liftOvernightParkingLocation,
        data.liftOvernightParkingLocation,
        cache,
        "liftOvernightParkingLocation",
        errors
    );
    subvalidate(
        Fields.portableToiletRequired,
        data.portableToiletRequired,
        cache,
        "portableToiletRequired",
        errors
    );
    subvalidate(
        Fields.portableToiletProposedLocation,
        data.portableToiletProposedLocation,
        cache,
        "portableToiletProposedLocation",
        errors
    );
    subvalidate(
        Fields.locationOfOnSiteWashrooms,
        data.locationOfOnSiteWashrooms,
        cache,
        "locationOfOnSiteWashrooms",
        errors
    );
    subvalidate(Fields.parking, data.parking, cache, "parking", errors);
    subvalidate(
        Fields.parkingNotes,
        data.parkingNotes,
        cache,
        "parkingNotes",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "REQUIRED_NOTICES": {
            const inner = Fields.requiredNotices.reduce(
                state.requiredNotices,
                data.requiredNotices,
                action.action,
                subcontext
            );
            return {
                state: { ...state, requiredNotices: inner.state },
                data: { ...data, requiredNotices: inner.data },
            };
        }
        case "SITE_STORAGE": {
            const inner = Fields.siteStorage.reduce(
                state.siteStorage,
                data.siteStorage,
                action.action,
                subcontext
            );
            return {
                state: { ...state, siteStorage: inner.state },
                data: { ...data, siteStorage: inner.data },
            };
        }
        case "LIFT_PARKING_REQUIRED": {
            const inner = Fields.liftParkingRequired.reduce(
                state.liftParkingRequired,
                data.liftParkingRequired,
                action.action,
                subcontext
            );
            return {
                state: { ...state, liftParkingRequired: inner.state },
                data: { ...data, liftParkingRequired: inner.data },
            };
        }
        case "LIFT_OVERNIGHT_PARKING_LOCATION": {
            const inner = Fields.liftOvernightParkingLocation.reduce(
                state.liftOvernightParkingLocation,
                data.liftOvernightParkingLocation,
                action.action,
                subcontext
            );
            return {
                state: { ...state, liftOvernightParkingLocation: inner.state },
                data: { ...data, liftOvernightParkingLocation: inner.data },
            };
        }
        case "PORTABLE_TOILET_REQUIRED": {
            const inner = Fields.portableToiletRequired.reduce(
                state.portableToiletRequired,
                data.portableToiletRequired,
                action.action,
                subcontext
            );
            return {
                state: { ...state, portableToiletRequired: inner.state },
                data: { ...data, portableToiletRequired: inner.data },
            };
        }
        case "PORTABLE_TOILET_PROPOSED_LOCATION": {
            const inner = Fields.portableToiletProposedLocation.reduce(
                state.portableToiletProposedLocation,
                data.portableToiletProposedLocation,
                action.action,
                subcontext
            );
            return {
                state: {
                    ...state,
                    portableToiletProposedLocation: inner.state,
                },
                data: { ...data, portableToiletProposedLocation: inner.data },
            };
        }
        case "LOCATION_OF_ON_SITE_WASHROOMS": {
            const inner = Fields.locationOfOnSiteWashrooms.reduce(
                state.locationOfOnSiteWashrooms,
                data.locationOfOnSiteWashrooms,
                action.action,
                subcontext
            );
            return {
                state: { ...state, locationOfOnSiteWashrooms: inner.state },
                data: { ...data, locationOfOnSiteWashrooms: inner.data },
            };
        }
        case "PARKING": {
            const inner = Fields.parking.reduce(
                state.parking,
                data.parking,
                action.action,
                subcontext
            );
            return {
                state: { ...state, parking: inner.state },
                data: { ...data, parking: inner.data },
            };
        }
        case "PARKING_NOTES": {
            const inner = Fields.parkingNotes.reduce(
                state.parkingNotes,
                data.parkingNotes,
                action.action,
                subcontext
            );
            return {
                state: { ...state, parkingNotes: inner.state },
                data: { ...data, parkingNotes: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    requiredNotices: function (
        props: WidgetExtraProps<typeof Fields.requiredNotices> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "REQUIRED_NOTICES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(context.status, "requiredNotices", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.requiredNotices.component
                state={context.state.requiredNotices}
                data={context.data.requiredNotices}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Required Notices"}
            />
        );
    },
    siteStorage: function (
        props: WidgetExtraProps<typeof Fields.siteStorage> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "SITE_STORAGE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "siteStorage", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.siteStorage.component
                state={context.state.siteStorage}
                data={context.data.siteStorage}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Site Storage"}
            />
        );
    },
    liftParkingRequired: function (
        props: WidgetExtraProps<typeof Fields.liftParkingRequired> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LIFT_PARKING_REQUIRED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "liftParkingRequired",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.liftParkingRequired.component
                state={context.state.liftParkingRequired}
                data={context.data.liftParkingRequired}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Lift Parking Required"}
            />
        );
    },
    liftOvernightParkingLocation: function (
        props: WidgetExtraProps<typeof Fields.liftOvernightParkingLocation> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LIFT_OVERNIGHT_PARKING_LOCATION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "liftOvernightParkingLocation",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.liftOvernightParkingLocation.component
                state={context.state.liftOvernightParkingLocation}
                data={context.data.liftOvernightParkingLocation}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Lift Overnight Parking Location"}
            />
        );
    },
    portableToiletRequired: function (
        props: WidgetExtraProps<typeof Fields.portableToiletRequired> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PORTABLE_TOILET_REQUIRED",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "portableToiletRequired",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.portableToiletRequired.component
                state={context.state.portableToiletRequired}
                data={context.data.portableToiletRequired}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Portable Toilet Required"}
            />
        );
    },
    portableToiletProposedLocation: function (
        props: WidgetExtraProps<
            typeof Fields.portableToiletProposedLocation
        > & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PORTABLE_TOILET_PROPOSED_LOCATION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "portableToiletProposedLocation",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.portableToiletProposedLocation.component
                state={context.state.portableToiletProposedLocation}
                data={context.data.portableToiletProposedLocation}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Portable Toilet Proposed Location"}
            />
        );
    },
    locationOfOnSiteWashrooms: function (
        props: WidgetExtraProps<typeof Fields.locationOfOnSiteWashrooms> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "LOCATION_OF_ON_SITE_WASHROOMS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "locationOfOnSiteWashrooms",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.locationOfOnSiteWashrooms.component
                state={context.state.locationOfOnSiteWashrooms}
                data={context.data.locationOfOnSiteWashrooms}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Location of on Site Washrooms"}
            />
        );
    },
    parking: function (
        props: WidgetExtraProps<typeof Fields.parking> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PARKING",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "parking", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.parking.component
                state={context.state.parking}
                data={context.data.parking}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Parking"}
            />
        );
    },
    parkingNotes: function (
        props: WidgetExtraProps<typeof Fields.parkingNotes> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PARKING_NOTES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "parkingNotes", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.parkingNotes.component
                state={context.state.parkingNotes}
                data={context.data.parkingNotes}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Parking Notes"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: HANDOFF_NOTE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let requiredNoticesState;
        {
            const inner = Fields.requiredNotices.initialize(
                data.requiredNotices,
                subcontext,
                subparameters.requiredNotices
            );
            requiredNoticesState = inner.state;
            data = { ...data, requiredNotices: inner.data };
        }
        let siteStorageState;
        {
            const inner = Fields.siteStorage.initialize(
                data.siteStorage,
                subcontext,
                subparameters.siteStorage
            );
            siteStorageState = inner.state;
            data = { ...data, siteStorage: inner.data };
        }
        let liftParkingRequiredState;
        {
            const inner = Fields.liftParkingRequired.initialize(
                data.liftParkingRequired,
                subcontext,
                subparameters.liftParkingRequired
            );
            liftParkingRequiredState = inner.state;
            data = { ...data, liftParkingRequired: inner.data };
        }
        let liftOvernightParkingLocationState;
        {
            const inner = Fields.liftOvernightParkingLocation.initialize(
                data.liftOvernightParkingLocation,
                subcontext,
                subparameters.liftOvernightParkingLocation
            );
            liftOvernightParkingLocationState = inner.state;
            data = { ...data, liftOvernightParkingLocation: inner.data };
        }
        let portableToiletRequiredState;
        {
            const inner = Fields.portableToiletRequired.initialize(
                data.portableToiletRequired,
                subcontext,
                subparameters.portableToiletRequired
            );
            portableToiletRequiredState = inner.state;
            data = { ...data, portableToiletRequired: inner.data };
        }
        let portableToiletProposedLocationState;
        {
            const inner = Fields.portableToiletProposedLocation.initialize(
                data.portableToiletProposedLocation,
                subcontext,
                subparameters.portableToiletProposedLocation
            );
            portableToiletProposedLocationState = inner.state;
            data = { ...data, portableToiletProposedLocation: inner.data };
        }
        let locationOfOnSiteWashroomsState;
        {
            const inner = Fields.locationOfOnSiteWashrooms.initialize(
                data.locationOfOnSiteWashrooms,
                subcontext,
                subparameters.locationOfOnSiteWashrooms
            );
            locationOfOnSiteWashroomsState = inner.state;
            data = { ...data, locationOfOnSiteWashrooms: inner.data };
        }
        let parkingState;
        {
            const inner = Fields.parking.initialize(
                data.parking,
                subcontext,
                subparameters.parking
            );
            parkingState = inner.state;
            data = { ...data, parking: inner.data };
        }
        let parkingNotesState;
        {
            const inner = Fields.parkingNotes.initialize(
                data.parkingNotes,
                subcontext,
                subparameters.parkingNotes
            );
            parkingNotesState = inner.state;
            data = { ...data, parkingNotes: inner.data };
        }
        let state = {
            initialParameters: parameters,
            requiredNotices: requiredNoticesState,
            siteStorage: siteStorageState,
            liftParkingRequired: liftParkingRequiredState,
            liftOvernightParkingLocation: liftOvernightParkingLocationState,
            portableToiletRequired: portableToiletRequiredState,
            portableToiletProposedLocation: portableToiletProposedLocationState,
            locationOfOnSiteWashrooms: locationOfOnSiteWashroomsState,
            parking: parkingState,
            parkingNotes: parkingNotesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={HANDOFF_NOTE_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    requiredNotices: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.requiredNotices>
    >;
    siteStorage: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.siteStorage>
    >;
    liftParkingRequired: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.liftParkingRequired>
    >;
    liftOvernightParkingLocation: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.liftOvernightParkingLocation>
    >;
    portableToiletRequired: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.portableToiletRequired>
    >;
    portableToiletProposedLocation: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.portableToiletProposedLocation>
    >;
    locationOfOnSiteWashrooms: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.locationOfOnSiteWashrooms>
    >;
    parking: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.parking>
    >;
    parkingNotes: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.parkingNotes>
    >;
};
// END MAGIC -- DO NOT EDIT

import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { RichTextWidget } from "../../rich-text-widget";
import { HandoffNote, HANDOFF_NOTE_META } from "./table";

export type Data = HandoffNote;

export const Fields = {
    clientSuccessCommunications: OptionalFormField(RichTextWidget),
    writtenOrVerbalPromises: OptionalFormField(RichTextWidget),
};

function Component(props: Props) {
    return (
        <>
            <widgets.clientSuccessCommunications label="Communications" />
            <widgets.writtenOrVerbalPromises />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.clientSuccessCommunications> &
    WidgetContext<typeof Fields.writtenOrVerbalPromises>;
type ExtraProps = {};
type BaseState = {
    clientSuccessCommunications: WidgetState<
        typeof Fields.clientSuccessCommunications
    >;
    writtenOrVerbalPromises: WidgetState<typeof Fields.writtenOrVerbalPromises>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "CLIENT_SUCCESS_COMMUNICATIONS";
          action: WidgetAction<typeof Fields.clientSuccessCommunications>;
      }
    | {
          type: "WRITTEN_OR_VERBAL_PROMISES";
          action: WidgetAction<typeof Fields.writtenOrVerbalPromises>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.clientSuccessCommunications,
        data.clientSuccessCommunications,
        cache,
        "clientSuccessCommunications",
        errors
    );
    subvalidate(
        Fields.writtenOrVerbalPromises,
        data.writtenOrVerbalPromises,
        cache,
        "writtenOrVerbalPromises",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "CLIENT_SUCCESS_COMMUNICATIONS": {
            const inner = Fields.clientSuccessCommunications.reduce(
                state.clientSuccessCommunications,
                data.clientSuccessCommunications,
                action.action,
                subcontext
            );
            return {
                state: { ...state, clientSuccessCommunications: inner.state },
                data: { ...data, clientSuccessCommunications: inner.data },
            };
        }
        case "WRITTEN_OR_VERBAL_PROMISES": {
            const inner = Fields.writtenOrVerbalPromises.reduce(
                state.writtenOrVerbalPromises,
                data.writtenOrVerbalPromises,
                action.action,
                subcontext
            );
            return {
                state: { ...state, writtenOrVerbalPromises: inner.state },
                data: { ...data, writtenOrVerbalPromises: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    clientSuccessCommunications: function (
        props: WidgetExtraProps<typeof Fields.clientSuccessCommunications> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "CLIENT_SUCCESS_COMMUNICATIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "clientSuccessCommunications",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.clientSuccessCommunications.component
                state={context.state.clientSuccessCommunications}
                data={context.data.clientSuccessCommunications}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Client Success Communications"}
            />
        );
    },
    writtenOrVerbalPromises: function (
        props: WidgetExtraProps<typeof Fields.writtenOrVerbalPromises> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "WRITTEN_OR_VERBAL_PROMISES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "writtenOrVerbalPromises",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.writtenOrVerbalPromises.component
                state={context.state.writtenOrVerbalPromises}
                data={context.data.writtenOrVerbalPromises}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Written or Verbal Promises"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: HANDOFF_NOTE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let clientSuccessCommunicationsState;
        {
            const inner = Fields.clientSuccessCommunications.initialize(
                data.clientSuccessCommunications,
                subcontext,
                subparameters.clientSuccessCommunications
            );
            clientSuccessCommunicationsState = inner.state;
            data = { ...data, clientSuccessCommunications: inner.data };
        }
        let writtenOrVerbalPromisesState;
        {
            const inner = Fields.writtenOrVerbalPromises.initialize(
                data.writtenOrVerbalPromises,
                subcontext,
                subparameters.writtenOrVerbalPromises
            );
            writtenOrVerbalPromisesState = inner.state;
            data = { ...data, writtenOrVerbalPromises: inner.data };
        }
        let state = {
            initialParameters: parameters,
            clientSuccessCommunications: clientSuccessCommunicationsState,
            writtenOrVerbalPromises: writtenOrVerbalPromisesState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={HANDOFF_NOTE_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    clientSuccessCommunications: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.clientSuccessCommunications>
    >;
    writtenOrVerbalPromises: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.writtenOrVerbalPromises>
    >;
};
// END MAGIC -- DO NOT EDIT

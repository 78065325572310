import * as React from "react";
import { Table } from "react-bootstrap";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi, useQuickRecords } from "../../../clay/quick-cache";
import { DateWidget } from "../../../clay/widgets/DateWidget";
import { simpleLinkWidget } from "../../../clay/widgets/dropdown-link-widget";
import { FormField, OptionalFormField } from "../../../clay/widgets/FormField";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { FieldRow } from "../../../clay/widgets/layout";
import { ListWidget } from "../../../clay/widgets/ListWidget";
import { SimpleListWrapper } from "../../../clay/widgets/SimpleListWrapper";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { ROLE_CERTIFIED_FOREMAN, USER_META } from "../../user/table";
import { ReactContext as ProjectCertifiedForemenCommunicationWidgetReactContext } from "../ProjectCertifiedForemenCommunicationWidget.widget";
import { AnticipatedDurationLinkWidget } from "../types/link";
import {
    ANTICIPATED_CREW_SIZE_META,
    AVAILABLE_WORKING_DAYS_META,
} from "../types/table";
import MockupExpectationLineWidget from "./MockupExpectationLineWidget.widget";
import { HandoffNote, HANDOFF_NOTE_META } from "./table";

export type Data = HandoffNote;

const NumberedListWidget = FormField(
    ListWidget(SimpleListWrapper(TextWidget, undefined, true), {
        emptyOk: true,
    })
);

function reduce(state: State, data: Data, action: Action, context: Context) {
    const inner = baseReduce(state, data, action, context);
    if (action.type === "PROJECTED_START_DATE" && action.action.type == "SET") {
        if (action.action.value === null) {
            return {
                ...inner,
                data: {
                    ...inner.data,
                    projectedCompletionDate: null,
                },
            };
        } else {
            let base = data.projectedStartDate;
            if (!base) {
                return inner;
            }
            let res = null;
            switch (data.anticipatedDuration) {
                case "11b85843-e54d-4e54-9f91-8df90be79ffd":
                    res = base.addWorkingDays(1);
                    break;
                case "ea1f57fd-b79f-4b79-93a6-7d3ac6f6849b":
                    res = base.addWeeks(1);
                    break;
                case "fb666bbb-10fd-410f-9360-313679d0487b":
                    res = base.addWorkingDays(3);
                case "990a0b06-94d5-494d-94ad-514adc40d858":
                    res = base.addWeeks(4);
                    break;
                case "3928d7c2-d15d-4d15-8378-c037a362f987":
                    res = base.addWeeks(2);
                    break;
                case "20394045-68d4-468d-9c4b-61c476498d30":
                    res = base.addWeeks(8);
                    break;
                case "c905f50c-df0d-4df0-9a75-89a7921bbaf8":
                    res = base.addDays(6);
                    break;
                case "e0cc4aa0-23d4-423d-92b7-a12b30d1cc25":
                    res = base.addWeeks(12);
                    break;
            }

            if (res) {
                return {
                    ...inner,
                    data: {
                        ...inner.data,
                        projectedCompletionDate: res,
                    },
                };
            }
        }
    }
    return inner;
}

export const Fields = {
    projectedStartDate: OptionalFormField(DateWidget),
    projectedCompletionDate: OptionalFormField(DateWidget),
    anticipatedDuration: OptionalFormField(AnticipatedDurationLinkWidget),
    availableWorkingDays: OptionalFormField(
        simpleLinkWidget(AVAILABLE_WORKING_DAYS_META)
    ),
    anticipatedCrewSize: OptionalFormField(
        simpleLinkWidget(ANTICIPATED_CREW_SIZE_META)
    ),
    projectPhases: NumberedListWidget,
    preexistingConditions: NumberedListWidget,
    potentialForUnforeseenWork: NumberedListWidget,
    mockupExpectations: ListWidget(MockupExpectationLineWidget, {
        emptyOk: true,
    }),
};

function Component(props: Props) {
    const projectContext = React.useContext(
        ProjectCertifiedForemenCommunicationWidgetReactContext
    )!;

    const certifiedForemen = useQuickRecords(
        USER_META,
        projectContext.data.personnel
            .filter((role) => role.role === ROLE_CERTIFIED_FOREMAN)
            .map((x) => x.user!)
    );
    return (
        <>
            <FieldRow>
                <widgets.projectedStartDate />
                <widgets.projectedCompletionDate />
                <widgets.anticipatedDuration />
            </FieldRow>
            <FieldRow>
                <widgets.availableWorkingDays label="Available Working Days/Hours" />
                <widgets.anticipatedCrewSize />
            </FieldRow>
            <Table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Mockup Expectation</th>
                        <th>Note</th>
                        <th>Due</th>
                        <th />
                    </tr>
                </thead>
                <widgets.mockupExpectations
                    extraItemForAdd
                    containerClass="tbody"
                />
            </Table>
            <widgets.projectPhases extraItemForAdd />
            <widgets.preexistingConditions
                label="Pre-existing Conditions"
                extraItemForAdd
            />
            <widgets.potentialForUnforeseenWork extraItemForAdd />
        </>
    );
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.projectedStartDate> &
    WidgetContext<typeof Fields.projectedCompletionDate> &
    WidgetContext<typeof Fields.anticipatedDuration> &
    WidgetContext<typeof Fields.availableWorkingDays> &
    WidgetContext<typeof Fields.anticipatedCrewSize> &
    WidgetContext<typeof Fields.projectPhases> &
    WidgetContext<typeof Fields.preexistingConditions> &
    WidgetContext<typeof Fields.potentialForUnforeseenWork> &
    WidgetContext<typeof Fields.mockupExpectations>;
type ExtraProps = {};
type BaseState = {
    projectedStartDate: WidgetState<typeof Fields.projectedStartDate>;
    projectedCompletionDate: WidgetState<typeof Fields.projectedCompletionDate>;
    anticipatedDuration: WidgetState<typeof Fields.anticipatedDuration>;
    availableWorkingDays: WidgetState<typeof Fields.availableWorkingDays>;
    anticipatedCrewSize: WidgetState<typeof Fields.anticipatedCrewSize>;
    projectPhases: WidgetState<typeof Fields.projectPhases>;
    preexistingConditions: WidgetState<typeof Fields.preexistingConditions>;
    potentialForUnforeseenWork: WidgetState<
        typeof Fields.potentialForUnforeseenWork
    >;
    mockupExpectations: WidgetState<typeof Fields.mockupExpectations>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | {
          type: "PROJECTED_START_DATE";
          action: WidgetAction<typeof Fields.projectedStartDate>;
      }
    | {
          type: "PROJECTED_COMPLETION_DATE";
          action: WidgetAction<typeof Fields.projectedCompletionDate>;
      }
    | {
          type: "ANTICIPATED_DURATION";
          action: WidgetAction<typeof Fields.anticipatedDuration>;
      }
    | {
          type: "AVAILABLE_WORKING_DAYS";
          action: WidgetAction<typeof Fields.availableWorkingDays>;
      }
    | {
          type: "ANTICIPATED_CREW_SIZE";
          action: WidgetAction<typeof Fields.anticipatedCrewSize>;
      }
    | {
          type: "PROJECT_PHASES";
          action: WidgetAction<typeof Fields.projectPhases>;
      }
    | {
          type: "PREEXISTING_CONDITIONS";
          action: WidgetAction<typeof Fields.preexistingConditions>;
      }
    | {
          type: "POTENTIAL_FOR_UNFORESEEN_WORK";
          action: WidgetAction<typeof Fields.potentialForUnforeseenWork>;
      }
    | {
          type: "MOCKUP_EXPECTATIONS";
          action: WidgetAction<typeof Fields.mockupExpectations>;
      };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(
        Fields.projectedStartDate,
        data.projectedStartDate,
        cache,
        "projectedStartDate",
        errors
    );
    subvalidate(
        Fields.projectedCompletionDate,
        data.projectedCompletionDate,
        cache,
        "projectedCompletionDate",
        errors
    );
    subvalidate(
        Fields.anticipatedDuration,
        data.anticipatedDuration,
        cache,
        "anticipatedDuration",
        errors
    );
    subvalidate(
        Fields.availableWorkingDays,
        data.availableWorkingDays,
        cache,
        "availableWorkingDays",
        errors
    );
    subvalidate(
        Fields.anticipatedCrewSize,
        data.anticipatedCrewSize,
        cache,
        "anticipatedCrewSize",
        errors
    );
    subvalidate(
        Fields.projectPhases,
        data.projectPhases,
        cache,
        "projectPhases",
        errors
    );
    subvalidate(
        Fields.preexistingConditions,
        data.preexistingConditions,
        cache,
        "preexistingConditions",
        errors
    );
    subvalidate(
        Fields.potentialForUnforeseenWork,
        data.potentialForUnforeseenWork,
        cache,
        "potentialForUnforeseenWork",
        errors
    );
    subvalidate(
        Fields.mockupExpectations,
        data.mockupExpectations,
        cache,
        "mockupExpectations",
        errors
    );
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "PROJECTED_START_DATE": {
            const inner = Fields.projectedStartDate.reduce(
                state.projectedStartDate,
                data.projectedStartDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectedStartDate: inner.state },
                data: { ...data, projectedStartDate: inner.data },
            };
        }
        case "PROJECTED_COMPLETION_DATE": {
            const inner = Fields.projectedCompletionDate.reduce(
                state.projectedCompletionDate,
                data.projectedCompletionDate,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectedCompletionDate: inner.state },
                data: { ...data, projectedCompletionDate: inner.data },
            };
        }
        case "ANTICIPATED_DURATION": {
            const inner = Fields.anticipatedDuration.reduce(
                state.anticipatedDuration,
                data.anticipatedDuration,
                action.action,
                subcontext
            );
            return {
                state: { ...state, anticipatedDuration: inner.state },
                data: { ...data, anticipatedDuration: inner.data },
            };
        }
        case "AVAILABLE_WORKING_DAYS": {
            const inner = Fields.availableWorkingDays.reduce(
                state.availableWorkingDays,
                data.availableWorkingDays,
                action.action,
                subcontext
            );
            return {
                state: { ...state, availableWorkingDays: inner.state },
                data: { ...data, availableWorkingDays: inner.data },
            };
        }
        case "ANTICIPATED_CREW_SIZE": {
            const inner = Fields.anticipatedCrewSize.reduce(
                state.anticipatedCrewSize,
                data.anticipatedCrewSize,
                action.action,
                subcontext
            );
            return {
                state: { ...state, anticipatedCrewSize: inner.state },
                data: { ...data, anticipatedCrewSize: inner.data },
            };
        }
        case "PROJECT_PHASES": {
            const inner = Fields.projectPhases.reduce(
                state.projectPhases,
                data.projectPhases,
                action.action,
                subcontext
            );
            return {
                state: { ...state, projectPhases: inner.state },
                data: { ...data, projectPhases: inner.data },
            };
        }
        case "PREEXISTING_CONDITIONS": {
            const inner = Fields.preexistingConditions.reduce(
                state.preexistingConditions,
                data.preexistingConditions,
                action.action,
                subcontext
            );
            return {
                state: { ...state, preexistingConditions: inner.state },
                data: { ...data, preexistingConditions: inner.data },
            };
        }
        case "POTENTIAL_FOR_UNFORESEEN_WORK": {
            const inner = Fields.potentialForUnforeseenWork.reduce(
                state.potentialForUnforeseenWork,
                data.potentialForUnforeseenWork,
                action.action,
                subcontext
            );
            return {
                state: { ...state, potentialForUnforeseenWork: inner.state },
                data: { ...data, potentialForUnforeseenWork: inner.data },
            };
        }
        case "MOCKUP_EXPECTATIONS": {
            const inner = Fields.mockupExpectations.reduce(
                state.mockupExpectations,
                data.mockupExpectations,
                action.action,
                subcontext
            );
            return {
                state: { ...state, mockupExpectations: inner.state },
                data: { ...data, mockupExpectations: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    projectedStartDate: function (
        props: WidgetExtraProps<typeof Fields.projectedStartDate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECTED_START_DATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectedStartDate",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectedStartDate.component
                state={context.state.projectedStartDate}
                data={context.data.projectedStartDate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Projected Start Date"}
            />
        );
    },
    projectedCompletionDate: function (
        props: WidgetExtraProps<typeof Fields.projectedCompletionDate> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECTED_COMPLETION_DATE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "projectedCompletionDate",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectedCompletionDate.component
                state={context.state.projectedCompletionDate}
                data={context.data.projectedCompletionDate}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Projected Completion Date"}
            />
        );
    },
    anticipatedDuration: function (
        props: WidgetExtraProps<typeof Fields.anticipatedDuration> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ANTICIPATED_DURATION",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "anticipatedDuration",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.anticipatedDuration.component
                state={context.state.anticipatedDuration}
                data={context.data.anticipatedDuration}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Anticipated Duration"}
            />
        );
    },
    availableWorkingDays: function (
        props: WidgetExtraProps<typeof Fields.availableWorkingDays> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "AVAILABLE_WORKING_DAYS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "availableWorkingDays",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.availableWorkingDays.component
                state={context.state.availableWorkingDays}
                data={context.data.availableWorkingDays}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Available Working Days"}
            />
        );
    },
    anticipatedCrewSize: function (
        props: WidgetExtraProps<typeof Fields.anticipatedCrewSize> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "ANTICIPATED_CREW_SIZE",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "anticipatedCrewSize",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.anticipatedCrewSize.component
                state={context.state.anticipatedCrewSize}
                data={context.data.anticipatedCrewSize}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Anticipated Crew Size"}
            />
        );
    },
    projectPhases: function (
        props: WidgetExtraProps<typeof Fields.projectPhases> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PROJECT_PHASES",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "projectPhases", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.projectPhases.component
                state={context.state.projectPhases}
                data={context.data.projectPhases}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Project Phases"}
            />
        );
    },
    preexistingConditions: function (
        props: WidgetExtraProps<typeof Fields.preexistingConditions> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PREEXISTING_CONDITIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "preexistingConditions",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.preexistingConditions.component
                state={context.state.preexistingConditions}
                data={context.data.preexistingConditions}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Preexisting Conditions"}
            />
        );
    },
    potentialForUnforeseenWork: function (
        props: WidgetExtraProps<typeof Fields.potentialForUnforeseenWork> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "POTENTIAL_FOR_UNFORESEEN_WORK",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "potentialForUnforeseenWork",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.potentialForUnforeseenWork.component
                state={context.state.potentialForUnforeseenWork}
                data={context.data.potentialForUnforeseenWork}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Potential for Unforeseen Work"}
            />
        );
    },
    mockupExpectations: function (
        props: WidgetExtraProps<typeof Fields.mockupExpectations> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "MOCKUP_EXPECTATIONS",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () =>
                subStatus(
                    context.status,
                    "mockupExpectations",
                    !!props.readOnly
                ),
            [context.status, props.readOnly]
        );
        return (
            <Fields.mockupExpectations.component
                state={context.state.mockupExpectations}
                data={context.data.mockupExpectations}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Mockup Expectations"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: HANDOFF_NOTE_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let projectedStartDateState;
        {
            const inner = Fields.projectedStartDate.initialize(
                data.projectedStartDate,
                subcontext,
                subparameters.projectedStartDate
            );
            projectedStartDateState = inner.state;
            data = { ...data, projectedStartDate: inner.data };
        }
        let projectedCompletionDateState;
        {
            const inner = Fields.projectedCompletionDate.initialize(
                data.projectedCompletionDate,
                subcontext,
                subparameters.projectedCompletionDate
            );
            projectedCompletionDateState = inner.state;
            data = { ...data, projectedCompletionDate: inner.data };
        }
        let anticipatedDurationState;
        {
            const inner = Fields.anticipatedDuration.initialize(
                data.anticipatedDuration,
                subcontext,
                subparameters.anticipatedDuration
            );
            anticipatedDurationState = inner.state;
            data = { ...data, anticipatedDuration: inner.data };
        }
        let availableWorkingDaysState;
        {
            const inner = Fields.availableWorkingDays.initialize(
                data.availableWorkingDays,
                subcontext,
                subparameters.availableWorkingDays
            );
            availableWorkingDaysState = inner.state;
            data = { ...data, availableWorkingDays: inner.data };
        }
        let anticipatedCrewSizeState;
        {
            const inner = Fields.anticipatedCrewSize.initialize(
                data.anticipatedCrewSize,
                subcontext,
                subparameters.anticipatedCrewSize
            );
            anticipatedCrewSizeState = inner.state;
            data = { ...data, anticipatedCrewSize: inner.data };
        }
        let projectPhasesState;
        {
            const inner = Fields.projectPhases.initialize(
                data.projectPhases,
                subcontext,
                subparameters.projectPhases
            );
            projectPhasesState = inner.state;
            data = { ...data, projectPhases: inner.data };
        }
        let preexistingConditionsState;
        {
            const inner = Fields.preexistingConditions.initialize(
                data.preexistingConditions,
                subcontext,
                subparameters.preexistingConditions
            );
            preexistingConditionsState = inner.state;
            data = { ...data, preexistingConditions: inner.data };
        }
        let potentialForUnforeseenWorkState;
        {
            const inner = Fields.potentialForUnforeseenWork.initialize(
                data.potentialForUnforeseenWork,
                subcontext,
                subparameters.potentialForUnforeseenWork
            );
            potentialForUnforeseenWorkState = inner.state;
            data = { ...data, potentialForUnforeseenWork: inner.data };
        }
        let mockupExpectationsState;
        {
            const inner = Fields.mockupExpectations.initialize(
                data.mockupExpectations,
                subcontext,
                subparameters.mockupExpectations
            );
            mockupExpectationsState = inner.state;
            data = { ...data, mockupExpectations: inner.data };
        }
        let state = {
            initialParameters: parameters,
            projectedStartDate: projectedStartDateState,
            projectedCompletionDate: projectedCompletionDateState,
            anticipatedDuration: anticipatedDurationState,
            availableWorkingDays: availableWorkingDaysState,
            anticipatedCrewSize: anticipatedCrewSizeState,
            projectPhases: projectPhasesState,
            preexistingConditions: preexistingConditionsState,
            potentialForUnforeseenWork: potentialForUnforeseenWorkState,
            mockupExpectations: mockupExpectationsState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext meta={HANDOFF_NOTE_META} value={props.data}>
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: reduce,
};
export default Widget;
type Widgets = {
    projectedStartDate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectedStartDate>
    >;
    projectedCompletionDate: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectedCompletionDate>
    >;
    anticipatedDuration: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.anticipatedDuration>
    >;
    availableWorkingDays: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.availableWorkingDays>
    >;
    anticipatedCrewSize: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.anticipatedCrewSize>
    >;
    projectPhases: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.projectPhases>
    >;
    preexistingConditions: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.preexistingConditions>
    >;
    potentialForUnforeseenWork: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.potentialForUnforeseenWork>
    >;
    mockupExpectations: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.mockupExpectations>
    >;
};
// END MAGIC -- DO NOT EDIT

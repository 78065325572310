import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { ColorWidget } from "../../../clay/widgets/ColorWidget";
import {
    RecordContext,
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    Widget,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { MasterFormatDivision, MASTER_FORMAT_DIVISION_META } from "./table";

export type Data = MasterFormatDivision;

const Fields = {
    prefix: TextWidget,
    color: ColorWidget,
};

function Component(props: Props) {
    throw new Error("Not implemented");
}

// BEGIN MAGIC -- DO NOT EDIT
type Context = {} & WidgetContext<typeof Fields.prefix> &
    WidgetContext<typeof Fields.color>;
type ExtraProps = {};
type BaseState = {
    prefix: WidgetState<typeof Fields.prefix>;
    color: WidgetState<typeof Fields.color>;
    initialParameters?: string[];
};
export type State = BaseState;

type BaseAction =
    | never
    | { type: "PREFIX"; action: WidgetAction<typeof Fields.prefix> }
    | { type: "COLOR"; action: WidgetAction<typeof Fields.color> };

export type Action = BaseAction;

export type Props = WidgetProps<State, Data, Action, ExtraProps>;

function baseValidate(data: Data, cache: QuickCacheApi) {
    const errors: ValidationError[] = [];
    subvalidate(Fields.prefix, data.prefix, cache, "prefix", errors);
    subvalidate(Fields.color, data.color, cache, "color", errors);
    return errors;
}
function baseReduce(
    state: State,
    data: Data,
    action: BaseAction,
    context: Context
): WidgetResult<State, Data> {
    let subcontext = context;
    switch (action.type) {
        case "PREFIX": {
            const inner = Fields.prefix.reduce(
                state.prefix,
                data.prefix,
                action.action,
                subcontext
            );
            return {
                state: { ...state, prefix: inner.state },
                data: { ...data, prefix: inner.data },
            };
        }
        case "COLOR": {
            const inner = Fields.color.reduce(
                state.color,
                data.color,
                action.action,
                subcontext
            );
            return {
                state: { ...state, color: inner.state },
                data: { ...data, color: inner.data },
            };
        }
    }
}
export type ReactContextType = {
    state: State;
    data: Data;
    dispatch: (action: Action) => void;
    status: WidgetStatus;
};
export const ReactContext = React.createContext<ReactContextType | undefined>(
    undefined
);
export const widgets: Widgets = {
    prefix: function (
        props: WidgetExtraProps<typeof Fields.prefix> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({
                    type: "PREFIX",
                    action,
                }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "prefix", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.prefix.component
                state={context.state.prefix}
                data={context.data.prefix}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Prefix"}
            />
        );
    },
    color: function (
        props: WidgetExtraProps<typeof Fields.color> & {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        }
    ) {
        const context = React.useContext(ReactContext) as ReactContextType;
        const subdispatch = React.useCallback(
            (action) =>
                (props.dispatch || context.dispatch)({ type: "COLOR", action }),
            [context.dispatch, props.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "color", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <Fields.color.component
                state={context.state.color}
                data={context.data.color}
                status={status}
                {...props}
                dispatch={subdispatch}
                label={props.label || "Color"}
            />
        );
    },
};
const Widget: RecordWidget<State, Data, Context, Action, ExtraProps> = {
    reactContext: ReactContext,
    fieldWidgets: widgets,
    dataMeta: MASTER_FORMAT_DIVISION_META,
    initialize(
        data: Data,
        context: Context,
        parameters?: string[]
    ): WidgetResult<State, Data> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        let prefixState;
        {
            const inner = Fields.prefix.initialize(
                data.prefix,
                subcontext,
                subparameters.prefix
            );
            prefixState = inner.state;
            data = { ...data, prefix: inner.data };
        }
        let colorState;
        {
            const inner = Fields.color.initialize(
                data.color,
                subcontext,
                subparameters.color
            );
            colorState = inner.state;
            data = { ...data, color: inner.data };
        }
        let state = {
            initialParameters: parameters,
            prefix: prefixState,
            color: colorState,
        };
        return {
            state,
            data,
        };
    },
    validate: baseValidate,
    component: React.memo((props: Props) => {
        return (
            <ReactContext.Provider value={props}>
                <RecordContext
                    meta={MASTER_FORMAT_DIVISION_META}
                    value={props.data}
                >
                    {Component(props)}
                </RecordContext>
            </ReactContext.Provider>
        );
    }, propCheck),
    reduce: baseReduce,
};
export default Widget;
type Widgets = {
    prefix: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.prefix>
    >;
    color: React.SFC<
        {
            label?: string;
            readOnly?: boolean;
            dispatch?: (action: Action) => void;
        } & WidgetExtraProps<typeof Fields.color>
    >;
};
// END MAGIC -- DO NOT EDIT
